import React, { useState } from 'react';

import { getMessages } from 'api';

const MessageContext = React.createContext({
  messages: [],
  hasUnreadMessages: false,
  fetchMessages: () => {},
});

function MessageProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const fetchMessages = () => {
    getMessages()
      .then((response) => {
        setMessages(response.data);

        const hasUnreadMessages = response.data.some(
          (message) => message.isRead === false
        );
        setHasUnreadMessages(hasUnreadMessages);
      })
      .catch();
  };

  return (
    <MessageContext.Provider
      value={{ messages, hasUnreadMessages, fetchMessages }}
    >
      {children}
    </MessageContext.Provider>
  );
}

export { MessageContext, MessageProvider };
