import { FormulaProvider } from '@visma/formula';
import { useKeycloak } from '@react-keycloak/web';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

export default function FormulaConfig({ children }) {
  const { token } = useKeycloak().keycloak;
  return (
    <FormulaProvider
      axios={(axios) => {
        axios.defaults.baseURL = runtimeConfig.formulaUrl;

        if (token) {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } else {
          delete axios.defaults.headers.common.Authorization;
        }
      }}
    >
      {children}
    </FormulaProvider>
  );
}
