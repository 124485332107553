export default function hasRole(user, { realm, resource = {} }) {
  return user
    ? testMaybeArray(realm, (role) => user.keycloak.hasRealmRole(role)) ||
        Object.entries(resource).some(([resource, role]) =>
          testMaybeArray(role, (role) =>
            user.keycloak.hasResourceRole(role, resource)
          )
        )
    : false;
}

const testMaybeArray = (role, test) =>
  Array.isArray(role) ? role.some(test) : test(role);
