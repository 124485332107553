import React from 'react';
import { useIntl } from 'react-intl';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import { ProfilePage } from 'components/ProfilePage';
import messages from 'messages';

export const path = '/user';

export const component = function User() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.profile)}>
      <ProfilePage />
    </PageWithDrawer>
  );
};
