import { useAxiosSafe } from 'use-axios';
import invariant from 'tiny-invariant';
import { merge } from 'lodash';

const config = {};
const handler = {};

export let RuntimeConfigProvider = ({ defaults, children }) => {
  merge(config, defaults);
  return children;
};

if (process.env.REACT_APP_RUNTIME_CONFIG_PUBLIC_URL) {
  handler.get = (target, prop, receiver) => {
    if (['$$typeof'].includes(prop)) {
      return target[prop];
    }
    invariant(
      false,
      `You should not use config outside a <RuntimeConfigProvider>. Trying to read runtime config "${prop}".`
    );
  };
  // eslint-disable-next-line no-new-func
  const url = new Function(
    ...Object.keys(global.location),
    `return \`${process.env.REACT_APP_RUNTIME_CONFIG_PUBLIC_URL}\``
  )(...Object.values(global.location));

  RuntimeConfigProvider = ({ defaults, children }) => {
    const [, response] = useAxiosSafe(url);
    merge(config, defaults, response?.data);
    delete handler.get;
    return children;
  };
}

export default new Proxy(config, handler);
