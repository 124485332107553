import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { createApplicant, updateApplicant } from 'api';
import messages from 'messages';
import { SnackbarVariant } from '../../../constants';

const DEFAULT_APPLICANT = {
  socialSecurityNumber: '',
  email: '',
  validityPeriodStartDate: '',
  validityPeriodExpiryDate: '',
  applicantsLicenseExpiryDate: '',
  sectionIds: [],
};

export default function ApplicantForm({
  applicant,
  isEditing,
  handleClose,
  updateListCallback,
}) {
  const currentApplicant = applicant ? applicant : DEFAULT_APPLICANT;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [formError, setFormError] = useState({
    socialSecurityNumber: false,
    email: false,
  });
  const { sectionId } = useParams();

  const [formState, setFormState] = useState({
    id: currentApplicant.id,
    socialSecurityNumber: currentApplicant.socialSecurityNumber,
    email: currentApplicant.email,
    validityPeriodStartDate: currentApplicant.validityPeriodStartDate,
    validityPeriodExpiryDate: currentApplicant.validityPeriodExpiryDate,
    applicantsLicenseExpiryDate: currentApplicant.applicantsLicenseExpiryDate,
    sectionId: sectionId,
  });

  useEffect(() => {
    if (
      !formError.socialSecurityNumber &&
      !formError.email &&
      formState.socialSecurityNumber !== '' &&
      formState.email !== '' &&
      !formError.validityPeriodStartDate &&
      !formError.validityPeriodExpiryDate &&
      !formError.applicantsLicenseExpiryDate &&
      formState.sectionIds !== null
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [formState, formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });

    switch (name) {
      case 'socialSecurityNumber':
        setFormState({ ...formState, [name]: value.toUpperCase() });
        setFormError({
          ...formError,
          [name]:
            !/^[0-9]{6}[Aa-][0-9]{3}[A-FHJ-NPR-TU-Ya-fhj-npr-tu-y0-9]$/.test(
              value
            ),
        });
        break;
      case 'email':
        setFormError({
          ...formError,
          [name]: !/.+@.+\.[A-Za-z]+$/.test(value),
        });
        break;
      case 'validityPeriodStartDate':
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const startDate = new Date(value);
        const endDate = new Date(formState.validityPeriodExpiryDate);
        const diffInDays = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
        );

        setFormError({
          ...formError,
          validityPeriodStartDate: startDate < yesterday,
          validityPeriodExpiryDate: endDate < startDate || diffInDays > 395,
        });
        break;
      case 'validityPeriodExpiryDate':
        const validityStartDate = new Date(formState.validityPeriodStartDate);
        const expiryDate = new Date(value);
        const daysDiff = Math.ceil(
          (expiryDate.getTime() - validityStartDate.getTime()) /
            (1000 * 60 * 60 * 24)
        );

        setFormError({
          ...formError,
          validityPeriodExpiryDate:
            expiryDate < validityStartDate || daysDiff > 395,
          applicantsLicenseExpiryDate:
            new Date(formState.applicantsLicenseExpiryDate) > expiryDate,
        });
        break;
      case 'applicantsLicenseExpiryDate':
        const licenseExpiryDate = new Date(value);
        const validityExpiryDate = new Date(formState.validityPeriodExpiryDate);

        setFormError({
          ...formError,
          applicantsLicenseExpiryDate:
            licenseExpiryDate < new Date(formState.validityPeriodStartDate) ||
            licenseExpiryDate > validityExpiryDate,
        });
        break;
      default:
        break;
    }
  };

  const editApplicant = () => {
    if (!disableSubmit) {
      const formattedDates = {
        validityPeriodStartDate: new Date(
          formState.validityPeriodStartDate
        ).toLocaleDateString('en-GB'),
        validityPeriodExpiryDate: new Date(
          formState.validityPeriodExpiryDate
        ).toLocaleDateString('en-GB'),
        applicantsLicenseExpiryDate: new Date(
          formState.applicantsLicenseExpiryDate
        ).toLocaleDateString('en-GB'),
      };

      const newFormState = {
        ...formState,
        ...formattedDates,
        socialSecurityNumber: formState.socialSecurityNumber.trim(),
        email: formState.email.trim(),
      };

      updateApplicant(newFormState, formState.id)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_error), {
            variant: SnackbarVariant.ERROR,
          });
        });
    }
  };

  const createNewApplicant = () => {
    if (!disableSubmit) {
      const formattedDates = {
        validityPeriodStartDate: new Date(
          formState.validityPeriodStartDate
        ).toLocaleDateString('en-GB'),
        validityPeriodExpiryDate: new Date(
          formState.validityPeriodExpiryDate
        ).toLocaleDateString('en-GB'),
        applicantsLicenseExpiryDate: new Date(
          formState.applicantsLicenseExpiryDate
        ).toLocaleDateString('en-GB'),
      };

      const newFormState = { ...formState, ...formattedDates };

      createApplicant(newFormState)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              enqueueSnackbar(
                intl.formatMessage(messages.applicant_duplicate),
                {
                  variant: SnackbarVariant.ERROR,
                }
              );
            } else {
              enqueueSnackbar(intl.formatMessage(messages.general_error), {
                variant: SnackbarVariant.ERROR,
              });
            }
          }
        });
    }
  };
  const dialogContentText = isEditing
    ? messages.applicant_edit
    : messages.applicant_create;

  const actionButtonOnClick = isEditing ? editApplicant : createNewApplicant;

  return (
    <>
      <DialogTitle id="confirm-dialog-title">
        <Typography component="h3">
          {intl.formatMessage(messages.applicant)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <Typography component="subtitle2">
            {intl.formatMessage(dialogContentText)}
          </Typography>
        </DialogContentText>
        <form>
          <TextField
            variant="standard"
            required
            name="socialSecurityNumber"
            label="Henkilötunnus"
            fullWidth
            value={formState.socialSecurityNumber}
            onChange={handleChange}
            inputProps={{ maxLength: 11 }}
            error={formError.socialSecurityNumber}
            helperText={
              formError.socialSecurityNumber ? 'Tarkista henkilötunnus' : ''
            }
          />
          <TextField
            variant="standard"
            required
            name="email"
            label="Sähköposti"
            fullWidth
            value={formState.email}
            onChange={handleChange}
            error={formError.email}
            helperText={formError.email ? 'Tarkista sähköposti' : ''}
          />
          <TextField
            variant="standard"
            name="validityPeriodStartDate"
            label="Tunnuksen voimassaoloajan alkupäivä"
            type="date"
            format="dd/MM/yyyy"
            fullWidth
            value={formState.validityPeriodStartDate}
            onChange={handleChange}
            error={formError.validityPeriodStartDate}
            helperText={
              formError.validityPeriodStartDate ? 'Tarkista päivämäärät' : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="standard"
            name="validityPeriodExpiryDate"
            label="Tunnuksen voimassoloajan loppupäivä, max 13kk alkupäivästä"
            type="date"
            format="dd/MM/yyyy"
            fullWidth
            value={formState.validityPeriodExpiryDate}
            onChange={handleChange}
            error={formError.validityPeriodExpiryDate}
            helperText={
              formError.validityPeriodExpiryDate ? 'Tarkista päivämäärät' : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="standard"
            name="applicantsLicenseExpiryDate"
            label="Tunnusta haettava viimeistään"
            type="date"
            format="dd/MM/yyyy"
            fullWidth
            value={formState.applicantsLicenseExpiryDate}
            onChange={handleChange}
            error={formError.applicantsLicenseExpiryDate}
            helperText={
              formError.applicantsLicenseExpiryDate
                ? 'Tarkista päivämäärät'
                : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {intl.formatMessage(messages.back)}
        </Button>
        <Button
          disabled={disableSubmit}
          variant="contained"
          size="small"
          color="primary"
          onClick={actionButtonOnClick}
        >
          <FormattedMessage defaultMessage="Tallenna" />
        </Button>
      </DialogActions>
    </>
  );
}
