import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Email } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { useLogout } from '@super-template/axios-keycloak-react-auth-integration/src/api';
import { MessageContext } from 'contexts/MessageProvider';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    float: 'right',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.2),
    },
    marginRight: '7px',
    padding: '12px 12px !important',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.hallinto.violet,
      color: theme.palette.hallinto.blueDark,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  activeLink: {
    color: theme.palette.common.blueDark,
    backgroundColor: theme.palette.hallinto.pink,
    textDecoration: 'none',
    float: 'right',
    marginRight: '7px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.2),
    },
    padding: '12px 12px !important',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.hallinto.violet,
      color: theme.palette.hallinto.blueDark,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  child: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: '7px',
  },
  unread: {
    marginRight: '8px',
    color: theme.palette.hallinto.orange,
  },
}));

export default function UserMenu({ title }) {
  const classes = useStyles();
  const logout = useLogout();
  const intl = useIntl();
  const { hasUnreadMessages, fetchMessages } = useContext(MessageContext);

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Link to="#" color="inherit" onClick={logout} className={classes.link}>
        <div className={classes.child}>
          <ExitToAppIcon className={classes.icon} />
          {intl.formatMessage(messages.logout)}
        </div>
      </Link>
      <Link
        to="/user"
        className={
          title === intl.formatMessage(messages.profile)
            ? classes.activeLink
            : classes.link
        }
      >
        <div className={classes.child}>
          <PersonOutlineIcon className={classes.icon} />
          {intl.formatMessage(messages.profile_title)}
        </div>
      </Link>
      <Link
        to="/inbox"
        className={
          title === intl.formatMessage(messages.inbox)
            ? classes.activeLink
            : classes.link
        }
      >
        <div className={classes.child}>
          {hasUnreadMessages ? (
            <Email style={{}} className={classes.unread} />
          ) : (
            <Email className={classes.icon} />
          )}
          {intl.formatMessage(messages.inbox)}
        </div>
      </Link>
    </>
  );
}
