import { Button, Typography, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import { updateSection } from 'api';
import { SnackbarVariant } from '../../constants';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  descFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    minHeight: '50px',
  },
  textDisplay: {
    wordWrap: 'break-word',
    border: '1px solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.common.grey,
    padding: theme.spacing(1.5),
    minHeight: '50px',
  },
  button: {
    marginTop: theme.spacing(0.8),
    padding: '3px 5px',
    fontSize: '0.7rem',
  },
  descriptionLabel: {
    marginTop: theme.spacing(0.5),
  },
}));

export default function DescriptionField({
  section,
  hasOrganizationAdminRole,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = useState(null);
  const [editingDescription, setEditingDescription] = useState(false);

  useEffect(() => {
    setDescription(section.description);
  }, [section]);

  const handleSaveDescription = () => {
    if (editingDescription) {
      const newSection = {
        ...section,
        description,
      };

      updateSection(newSection, newSection.id)
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage(messages.description_update_success),
            {
              variant: SnackbarVariant.SUCCESS,
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage(messages.description_update_failed),
            {
              variant: SnackbarVariant.ERROR,
            }
          );
        });

      setEditingDescription(false);
    } else {
      setEditingDescription(true);
    }
  };

  return (
    <>
      <div className={classes.descFieldContainer}>
        {editingDescription ? (
          <TextField
            variant="outlined"
            id="Osa-alueen kuvaus"
            inputProps={{ maxLength: 250 }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={classes.textField}
          />
        ) : (
          <Typography
            variant="body1"
            className={classes.textDisplay}
            align="left"
          >
            {description ? description : 'Osa-alueelle ei ole lisätty kuvausta'}
          </Typography>
        )}
        <div className={classes.actionContainer}>
          <Typography
            variant="body2"
            align="left"
            className={classes.descriptionLabel}
          >
            {intl.formatMessage(messages.section_description)}
          </Typography>
          {hasOrganizationAdminRole && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleSaveDescription}
            >
              {editingDescription
                ? intl.formatMessage(messages.general_save)
                : intl.formatMessage(messages.general_edit)}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
