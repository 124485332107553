import { useEffect } from 'react';
// import globalAxios from 'axios';
import { axios } from 'api';

export default function useAxiosAuthHeaderUpdater(token) {
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }, [token]);
}
