import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'messages';
import jwt from 'jsonwebtoken';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import { RenewalFormView } from 'components/AccountRenewal/RenewalForm';
import { Typography } from '@material-ui/core';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

export const path = '/renewalform/:jwt';

export const component = function RenewalForm(props) {
  const intl = useIntl();
  const { jwt: token } = props.match.params;
  let renewalFormValues;
  try {
    renewalFormValues = jwt.verify(token, runtimeConfig.jwtPublicKey, {
      algorithms: ['RS256'],
    });
  } catch (err) {
    return (
      <PageWithDrawer title={intl.formatMessage(messages.forms)}>
        <Typography color="initial" variant="body1">
          {intl.formatMessage(messages.token_expired)}
        </Typography>
      </PageWithDrawer>
    );
  }

  return (
    <PageWithDrawer title={intl.formatMessage(messages.forms)}>
      <RenewalFormView formValues={renewalFormValues} />
    </PageWithDrawer>
  );
};
