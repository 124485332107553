import { Route, Switch } from 'react-router-dom';
import * as user from './User';
import * as admin from '../Admin';

export default function Login() {
  return (
    <Switch>
      <Route {...admin} />
      <Route {...user} />
      <Route component={user.component} />
    </Switch>
  );
}
