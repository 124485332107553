import { create } from '@postinumero/use-async';

const [, , useLocaleMessagesSafe] = create((locale) =>
  import(
    /* webpackChunkName: "compiled-lang.[request]" */
    `compiled-lang/${locale}`
  )
);

export default function useMessages({ locale, defaultLocale }) {
  const [, defaultMessages] = useLocaleMessagesSafe(defaultLocale);
  const [, messages] = useLocaleMessagesSafe(locale);

  return {
    ...defaultMessages,
    ...messages,
  };
}
