import { IntlProvider } from 'react-intl';
import useLocale from '../locale/useLocale';
import useMessages from './useMessages';
import defaultLocaleValue from '../locale/default';

export default function IntlMessagesProvider({
  locale,
  defaultLocale = defaultLocaleValue,
  messages,
  ...otherProps
}) {
  const [localeSetting] = useLocale();
  locale ??= localeSetting;

  return (
    <IntlProvider
      messages={{
        ...useMessages({ locale, defaultLocale }),
        ...messages,
      }}
      locale={locale}
      defaultLocale={defaultLocale}
      {...otherProps}
    />
  );
}
