import { Route, Switch } from 'react-router-dom';
import NotFound from '@super-template/core/src/NotFound';
import * as admin from './Admin';
import * as home from './Home';
import * as forms from './Forms';
import * as form from './Form';
import * as edit from './FormEdit';
import * as registration from './Registration';
import * as user from './User';
import * as listView from './ListView';
import * as sectionListView from './SectionListView';
import * as userListView from './UserListView';
import * as inbox from './Inbox';
import * as message from './Message';
import * as accountRenewal from './AccountRenewal/RenewalForm';
import * as accountRenewalSuccess from './AccountRenewal/RenewalSuccess';
import { useEffect, useState } from 'react';
import { getUserStatus } from '../../api.js';
import { useUserClaim } from '@super-template/axios-keycloak-react-auth-integration/src/useUserClaim.js';
import {
  removeUserStatus,
  setUserStatus,
  STATUS_APPLICANT,
} from '../../userUtils.js';
import ManagerLogin from '../ManagerLogin/ManagerLogin.js';

export default function Routes() {
  const ssn = useUserClaim('ssn');
  const [showManagerOrApplicantDialog, setShowManagerOrApplicantDialog] =
    useState(false);
  const [showOnlyConcentForm, setShowOnlyConcentForm] = useState('');

  const handleClose = () => {
    setShowManagerOrApplicantDialog(false);
  };

  useEffect(() => {
    getUserStatus(ssn).then((response) => {
      const userStatus = response.data;
      if (userStatus.manager && userStatus.applicant) {
        setShowOnlyConcentForm(false);
        setShowManagerOrApplicantDialog(true);
      } else if (userStatus.manager) {
        setShowOnlyConcentForm(true);
        setShowManagerOrApplicantDialog(true);
      } else if (userStatus.applicant) {
        setUserStatus(STATUS_APPLICANT);
      } else {
        removeUserStatus();
      }
    });
  }, [ssn]);

  return (
    <>
      <ManagerLogin
        open={showManagerOrApplicantDialog}
        handleClose={handleClose}
        showOnlyConcentForm={showOnlyConcentForm}
      />
      <Switch>
        <Route {...home} />
        <Route {...forms} />
        <Route {...edit} />
        <Route {...admin} />
        <Route {...registration} />
        <Route {...form} />
        <Route {...user} />
        <Route {...accountRenewalSuccess} exact />
        <Route {...accountRenewal} />
        <Route {...userListView} />
        <Route {...sectionListView} />
        <Route {...listView} />
        <Route {...inbox} />
        <Route {...message} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}
