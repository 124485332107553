import { ReactKeycloakProvider } from '@react-keycloak/web';
import { LinearProgress } from '@material-ui/core';
import getKeycloak from './getKeycloak';
import { setHasRoleFn } from '@postinumero/react-auth/lib/useHasRole';
import hasRole from './hasRole';
import Auth from './Auth';
import ProviderMock from './ProviderMock';
import { withTokenProvider } from './token';

setHasRoleFn(hasRole);

export const Provider = withTokenProvider(
  process.env.REACT_APP_KEYCLOAK_MOCK_USER
    ? ProviderMock
    : ReactKeycloakProvider
);

export default function AuthProvider({ children, ...props }) {
  const initOptions = {
    checkLoginIframe: false,
  };

  return (
    <Provider
      authClient={getKeycloak()}
      LoadingComponent={<LinearProgress />}
      initOptions={initOptions}
      {...props}
    >
      <Auth>{children}</Auth>
    </Provider>
  );
}
