import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'messages';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import { MessageView } from 'components/Message/MessageView';

export const path = '/message/:id';

export const component = function Message() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.message)}>
      <MessageView />
    </PageWithDrawer>
  );
};
