import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

import { useLogout } from '@super-template/axios-keycloak-react-auth-integration/src/api';

import { SnackbarVariant } from '../../../constants';
import { revokePermissions, fetchUserPermissionsAcceptedDate } from 'api';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    marginBottom: '.5rem',
  },
  accordionHeader: {
    maringLeft: '1rem',
    background: theme.palette.common.greyLight,
    color: theme.palette.white,
    '& > *': {
      color: theme.palette.white,
    },
  },
  accordionDetails: {
    padding: theme.spacing(1),
    borderBottom: '1px solid gray',
    marginBottom: theme.spacing(1),
  },
  buttons: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 0.3),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.3, 0),
    },
  },
  baseline: {
    display: 'flex',
    '& > * ': {
      padding: '0 2px',
    },
  },
}));

export const Assent = () => {
  const [showModify, setShowModify] = useState(false);
  const intl = useIntl();
  const classes = useStyles();
  const logout = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const [permissionsAcceptedDate, setPermissionsAcceptedDate] = useState(null);

  useEffect(() => {
    (async () => {
      const event = await fetchUserPermissionsAcceptedDate();
      if (event.status === 200) {
        setPermissionsAcceptedDate(event.data.createDateTime);
      }
    })();
  }, [setPermissionsAcceptedDate]);

  const revokeAccess = () => {
    revokePermissions()
      .then(() => {
        logout();
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage(messages.profile_revoke_concent_error),
          {
            variant: SnackbarVariant.ERROR,
          }
        );
        setShowModify(false);
      });
  };

  return (
    <Accordion className={classes.accordionContainer}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        arial-controls="panel1a-content"
        className={classes.accordionHeader}
      >
        <AssignmentIcon />
        <Typography style={{ paddingLeft: '1rem', color: 'black' }}>
          {intl.formatMessage(messages.profile_e_concent)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="flex-end">
          {!showModify ? (
            <>
              <Grid item xs={12} className={classes.accordionDetails}>
                <Typography className={classes.baseline}>
                  <CheckIcon />
                  {intl.formatMessage(messages.registration_accepted)}: &nbsp;
                  {permissionsAcceptedDate != null
                    ? intl.formatDate(permissionsAcceptedDate)
                    : intl.formatMessage(messages.general_not_found)}
                </Typography>
                <Typography className={classes.baseline}>
                  <CheckIcon />
                  {intl.formatMessage(messages.registration_step_1_short)}
                </Typography>
                <Typography className={classes.baseline}>
                  <CheckIcon />
                  {intl.formatMessage(messages.registration_step_2_short)}
                </Typography>
              </Grid>
              <Grid container item md={4} xs={12} direction="column">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => setShowModify(true)}
                >
                  {intl.formatMessage(messages.profile_edit_assent)}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} className={classes.accordionDetails}>
                <Typography>
                  {intl.formatMessage(messages.profile_modify_revoke)}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="row"
                justifyContent="flex-end"
              >
                <Grid item md={3} xs={12} className={classes.buttons}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => setShowModify(false)}
                    style={{ width: '100%' }}
                  >
                    {intl.formatMessage(messages.back)}
                  </Button>
                </Grid>
                <Grid item md={4} xs={12} className={classes.buttons}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={revokeAccess}
                    style={{ width: '100%' }}
                  >
                    {intl.formatMessage(messages.profile_revoke_assent)}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
