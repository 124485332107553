import React, { useEffect, useState } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  fetchOrganizationList,
  getOrganizationsForManager,
  fetchAllSections,
  fetchAllApplicants,
  fetchAllManagers,
  fetchAllSectionsForManager,
  fetchAllApplicantsForManager,
} from 'api';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    '& .MuiAutocomplete-popper': {
      position: 'fixed',
      '& .MuiPaper-root': {
        '& .MuiAutocomplete-listbox': {
          '& li': {
            fontSize: '0.8rem !important',
            textAlign: 'left',
          },
        },
      },
    },
  },
}));

const Search = () => {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  const [organizationList, setOrganizationList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const [activeFilters, setActiveFilters] = useState([]);

  const fetchOrganizations = () => {
    const fetchOrgsMethod = hasOrganizationAdminRole
      ? fetchOrganizationList
      : getOrganizationsForManager;

    fetchOrgsMethod().then((response) => {
      const typedList = response.data.map((obj) => ({
        ...obj,
        type: 'Organisaatio',
      }));
      setOrganizationList(typedList);
      fetchSections();
    });
  };

  const fetchSections = () => {
    const fetchSectionsMethod = hasOrganizationAdminRole
      ? fetchAllSections
      : fetchAllSectionsForManager;

    fetchSectionsMethod().then((response) => {
      const typedList = response.data.map((obj) => ({
        ...obj,
        type: 'Osa-alue',
      }));
      setSectionList(typedList);
      fetchApplicants(typedList);
    });
  };

  const fetchApplicants = (sectionList) => {
    const fetchApplicantsMethod = hasOrganizationAdminRole
      ? fetchAllApplicants
      : fetchAllApplicantsForManager;

    fetchApplicantsMethod().then((response) => {
      const typedList = [];
      response.data.forEach((obj) => {
        const section = sectionList.filter((s) => {
          return s.id === obj.sectionId;
        });
        typedList.push({
          ...obj,
          type: 'Hakija',
          sectionName: section[0].sectionName,
          organizationId: section[0].organizationId,
        });
      });
      setApplicantList(typedList);
      fetchManagers(sectionList);
    });
  };

  const fetchManagers = (sectionList) => {
    if (hasOrganizationAdminRole) {
      fetchAllManagers().then((response) => {
        const typedList = [];
        response.data.forEach((obj) => {
          obj.sectionIds.forEach((id) => {
            const section = sectionList.filter((s) => {
              return s.id === id;
            });
            typedList.push({
              ...obj,
              type: 'Manageri',
              sectionId: id,
              sectionName: section[0].sectionName,
              organizationId: section[0].organizationId,
            });
          });
        });
        setManagerList(typedList);
      });
    }
  };

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    if (value) {
      if (value.type === 'Organisaatio') {
        navigateToPage(`/listview/${value.id}`);
      } else if (value.type === 'Osa-alue') {
        navigateToPage(`/listview/${value.organizationId}/${value.id}`);
      } else if (value.type === 'Manageri') {
        navigateToPage(`/listview/${value.organizationId}/${value.sectionId}`);
      } else if (value.type === 'Hakija') {
        navigateToPage(`/listview/${value.organizationId}/${value.sectionId}`);
      }
    }
  };

  const handleCheckboxChange = (value) => {
    setActiveFilters(value);
  };

  const getFilteredSearchList = () => {
    const list = [];

    filteringOptions.forEach((f) => {
      if (activeFilters.includes(f)) {
        switch (f) {
          case 'Organisaatiot':
            list.push(...organizationList);
            break;
          case 'Osa-alueet':
            list.push(...sectionList);
            break;
          case 'Managerit':
            list.push(...managerList);
            break;
          case 'Hakijat':
            list.push(...applicantList);
            break;
          default:
            break;
        }
      }
    });
    if (list.length < 1) {
      list.push(...organizationList);
      list.push(...sectionList);
      list.push(...managerList);
      list.push(...applicantList);
    }
    return list;
  };

  const navigateToPage = (page) => {
    history.push(`${page}`);
  };

  const filteringOptions = [
    'Organisaatiot',
    'Osa-alueet',
    'Managerit',
    'Hakijat',
  ];

  const [searchOpen, setSearchOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'end',
        width: '60%',
        gridColumnGap: '10%',
      }}
    >
      <Autocomplete
        open={filterOpen}
        multiple
        id="checkbox"
        options={filteringOptions}
        fullWidth
        style={useStyles()}
        renderTags={() => null}
        disableClearable
        disableCloseOnSelect
        clearOnBlur={false}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        onBlur={() => {
          if (filterOpen) {
            setFilterOpen(true);
          }
        }}
        noOptionsText={'Ei tuloksia'}
        onChange={(event, value) => {
          handleCheckboxChange(value);
        }}
        renderOption={(option, { selected }) => (
          <li {...filteringOptions}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Haettavat kategoriat" />
        )}
      />

      <Autocomplete
        open={searchOpen}
        forcePopupIcon={false}
        options={getFilteredSearchList()}
        getOptionLabel={(option) => getOptionLabel(option)}
        fullWidth
        style={useStyles()}
        groupBy={(option) => option.type}
        clearOnBlur={false}
        onBlur={() => {
          setSearchOpen(false);
        }}
        noOptionsText={'Ei hakutuloksia'}
        onInputChange={(_, value) => {
          if (value.length === 0) {
            if (searchOpen) setSearchOpen(false);
          } else {
            if (!searchOpen) setSearchOpen(true);
          }
        }}
        onChange={(event, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField variant="standard" {...params} label={'Hae'} />
        )}
      />
    </div>
  );
};

const getOptionLabel = (option) => {
  switch (option.type) {
    case 'Organisaatio':
      return option.organizationName;
    case 'Osa-alue':
      return `${option.sectionName}, ${option.serviceCode}, ${option.costCentre}, ${option.title}, ${option.costCentre}`;
    case 'Hakija':
      return `Osa-alue: ${option.sectionName} - ${option.socialSecurityNumber}, ${option.email}`;
    case 'Manageri':
      return `Osa-alue: ${option.sectionName} - ${getEmployeeNumber(
        option
      )} ${getSsn(option)} ${option.firstName}, ${option.lastName}, ${
        option.email
      }`;
    default:
  }
};

const getSsn = (option) => {
  if (option.socialSecurityNumber) {
    return `${option.socialSecurityNumber},`;
  } else {
    return '';
  }
};
const getEmployeeNumber = (option) => {
  if (option.employeeNumber) {
    return `${option.employeeNumber},`;
  } else {
    return '';
  }
};

export default Search;
