import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { createOrganization, updateOrganization } from 'api';
import messages from 'messages';
import { SnackbarVariant } from '../../../constants';

export default function OrganizationForm({
  organization,
  isEditing,
  updateListCallback,
  handleClose,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [organizationName, setOrganizationName] = useState(
    organization?.organizationName
  );
  const [disableSubmit, setDisableSubmit] = useState(true);
  const organizationId = organization?.id;
  const intl = useIntl();

  const editOrganization = () => {
    updateOrganization(
      { ...organization, organizationName: organizationName },
      organizationId
    )
      .then(() => {
        enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
          variant: SnackbarVariant.SUCCESS,
        });
        updateListCallback();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            enqueueSnackbar(intl.formatMessage(messages.organization_exists), {
              variant: SnackbarVariant.ERROR,
            });
          } else {
            enqueueSnackbar(intl.formatMessage(messages.general_error), {
              variant: SnackbarVariant.ERROR,
            });
          }
        }
      });
  };

  const createNewOrganization = () => {
    createOrganization({ organizationName })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
          variant: SnackbarVariant.SUCCESS,
        });
        setOrganizationName('');
        updateListCallback();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            enqueueSnackbar(intl.formatMessage(messages.organization_exists), {
              variant: SnackbarVariant.ERROR,
            });
          } else {
            enqueueSnackbar(intl.formatMessage(messages.general_error), {
              variant: SnackbarVariant.ERROR,
            });
          }
        }
      });
  };

  useEffect(() => {
    if (organizationName && organizationName != null) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [organizationName]);

  const dialogContentText = isEditing
    ? messages.organization_edit
    : messages.organization_create;

  return (
    <>
      <DialogTitle id="confirm-dialog-title">
        <Typography variant="h3">
          {intl.formatMessage(messages.organization)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <Typography variant="subtitle2">
            {intl.formatMessage(dialogContentText)}
          </Typography>
        </DialogContentText>
        <TextField
          required
          variant="standard"
          helperText="Kenttä on pakollinen"
          id="name"
          label="Organisaation nimi"
          type="text"
          fullWidth
          inputProps={{ maxLength: 250 }}
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage defaultMessage="Peruuta" />
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={disableSubmit}
          onClick={isEditing ? editOrganization : createNewOrganization}
        >
          {intl.formatMessage(
            isEditing ? messages.general_edit : messages.send
          )}
        </Button>
      </DialogActions>
    </>
  );
}
