import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import messages from 'messages';
import FormResponseList from './FormResponseList';
import { fetchFormResponses } from 'api';
import { SnackbarVariant } from '../../../constants';

export const FormResponse = () => {
  const [fetchingLoader, setFetchingLoading] = useState(true);
  const [formResponses, setFormResponses] = useState([]);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const fetchResponses = useCallback(async () => {
    try {
      const res = await fetchFormResponses();
      setFormResponses(res.data);
    } catch (err) {
      enqueueSnackbar(intl.formatMessage(messages.form_fetch_error), {
        variant: SnackbarVariant.ERROR,
      });
    }
  }, [enqueueSnackbar, intl]);

  useEffect(() => {
    (async () => {
      await fetchResponses();
      setFetchingLoading(false);
    })();
  }, [fetchResponses]);

  return fetchingLoader ? (
    <CircularProgress />
  ) : (
    <FormResponseList forms={formResponses} fetchResponses={fetchResponses} />
  );
};
