import React, { useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ConcentForm } from './ConcentForm';
import { LoginForm } from './LoginForm';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '50vh',
    maxHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.grey,
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2.5rem',
    paddingTop: '2.5rem',
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  card: {
    margin: '1rem',
  },
}));

export default function ManagerLogin({
  open,
  handleClose,
  showOnlyConcentForm,
}) {
  const classes = useStyles();
  const [moveToConcentForm, setMoveToConcentForm] = useState(false);

  const handleManagerLogin = () => {
    setMoveToConcentForm(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogActions>
          {showOnlyConcentForm || moveToConcentForm ? (
            <ConcentForm handleClose={handleClose} />
          ) : (
            <LoginForm
              handleClose={handleClose}
              onManagerLogin={handleManagerLogin}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
