import { useEffect, useMemo, useReducer, useState } from 'react';
import { reactKeycloakWebContext } from '@react-keycloak/web/lib/context';
import useSessionStorage from '@postinumero/storage/useSessionStorage';
import { mapValues } from 'lodash';

function useAuthClientMock() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [sessionUser, setSessionUser] = useSessionStorage('mock_user');
  const { attributes, realm_access, resource_access, ...user } = JSON.parse(
    process.env.REACT_APP_KEYCLOAK_MOCK_USER
  );

  return useMemo(
    () => {
      const authenticated =
        process.env.REACT_APP_KEYCLOAK_MOCK_USER === sessionUser;
      return {
        tokenParsed: user,
        authenticated,
        login() {
          this.authenticated = true;
          setSessionUser(process.env.REACT_APP_KEYCLOAK_MOCK_USER);
          forceUpdate();
        },
        logout(options) {
          this.authenticated = false;
          setSessionUser();
          forceUpdate();
          if (options?.redirectUri) {
            window.location = options.redirectUri;
          }
        },
        hasRealmRole(role) {
          return realm_access?.roles?.includes(role);
        },
        hasResourceRole(role, resource) {
          return resource_access?.[resource]?.roles?.includes(role);
        },
        loadUserProfile() {
          return {
            attributes: { ...mapValues(attributes, (value) => [value]) },
          };
        },
        token: authenticated ? '<JWT>' : undefined,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default function ProviderMock({ LoadingComponent, onTokens, children }) {
  const [initialized, setInitialized] = useState();
  const authClient = useAuthClientMock();
  useEffect(() => {
    setTimeout(() => {
      onTokens({ token: authClient.token });
      setInitialized(true);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return initialized || !LoadingComponent ? (
    <reactKeycloakWebContext.Provider value={{ authClient, initialized }}>
      {children}
    </reactKeycloakWebContext.Provider>
  ) : (
    LoadingComponent
  );
}
