import { useIntl, FormattedMessage } from 'react-intl';
import { Grid, Card, CardActionArea, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSFILogin } from '@super-template/axios-keycloak-react-auth-integration/src/api';

import Page from 'components/layouts/Page';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  hero: {
    paddingBottom: '12rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4rem',
    },
  },
  rootText: {
    textAlign: 'center',
  },
  cardRoot: {
    width: '100%',
  },
  card: {
    margin: '0.5rem',
    background: theme.palette.hallinto.orangeLight,
    border: `1px solid ${theme.palette.hallinto.orangeBorder}`,
    '&:hover': {
      backgroundColor: theme.palette.hallinto.orangeDark,
      borderColor: theme.palette.hallinto.orange,
    },
    '&:active': {
      borderColor: '8px black',
    },
  },
  cardAction: {
    padding: '1.5rem 3rem',
  },
  cardText: {
    textAlign: 'center',
  },
}));

export const path = '/login';
export const exact = true;

export const component = function User(props) {
  const classes = useStyles();
  const intl = useIntl();
  const login = useSFILogin();
  const redirectUri =
    props.location.pathname.includes('renewalform') && props.location.pathname;

  return (
    <Page>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12} md={10} className={classes.hero}>
          <h1 className={classes.rootText}>
            <FormattedMessage defaultMessage="Tervetuloa! Ole hyvä ja kirjaudu sisään." />
          </h1>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={5} className={classes.cardRoot}>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.cardAction}
              onClick={() => login(!!redirectUri && redirectUri)}
            >
              <Typography variant="h6" className={classes.cardText}>
                {intl.formatMessage(messages.login)}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};
