import { useKeycloak } from '@react-keycloak/web';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

export const useLogin = () => {
  const { keycloak } = useKeycloak();

  return () => keycloak.login({ redirectUri: global.location.origin });
};

export const useSFILogin = () => {
  const { keycloak } = useKeycloak();

  return (redirectUri) =>
    keycloak.login({
      ...JSON.parse(runtimeConfig.keycloakLogin),
      redirectUri: redirectUri ? global.location.origin.redirectUri :  global.location.origin,
    });
};

export const useLogout = () => {
  const { keycloak } = useKeycloak();

  return () => {
    keycloak.logout({ redirectUri: global.location.origin });
    sessionStorage.clear();
    localStorage.clear();
  };
};
