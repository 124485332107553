import { createStateContext } from 'react-use';
import { Children, cloneElement } from 'react';

const [useSharedState, SharedStateProvider] = createStateContext();

function HandleTokenChange({ children }) {
  const [, setState] = useSharedState();

  return cloneElement(Children.only(children), {
    onTokens: ({ token }) => {
      setState(token);
    },
  });
}

export const withTokenProvider = (Provider) =>
  function WithTokenProvider(props) {
    return (
      <SharedStateProvider>
        <HandleTokenChange>
          <Provider {...props} />
        </HandleTokenChange>
      </SharedStateProvider>
    );
  };

export const useToken = () => useSharedState()[0];
