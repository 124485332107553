import { Grid, Card, CardActionArea, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import messages from 'messages';
import {
  STATUS_APPLICANT,
  STATUS_MANAGER,
  setUserStatus,
} from '../../userUtils.js';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '50vh',
    maxHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2.5rem',
    paddingTop: '2.5rem',
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  rootText: {
    textAlign: 'center',
  },
  cardRoot: {
    width: '200%',
  },
  card: {
    margin: '1rem',
  },
  cardAction: {
    background: theme.palette.hallinto.orangeLight,
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.hallinto.orangeBorder}`,
    '&:hover': {
      backgroundColor: theme.palette.hallinto.orangeDark,
      borderColor: theme.palette.hallinto.orange,
    },
    '&:active': {
      borderColor: '8px black',
    },
  },
  cardText: {
    textAlign: 'center',
  },
  paragraph: {
    margin: '0.5rem 0',
  },
}));

export const LoginForm = ({ handleClose, onManagerLogin }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleManager = () => {
    setUserStatus(STATUS_MANAGER);
    onManagerLogin();
  };

  const handleApplicant = () => {
    setUserStatus(STATUS_APPLICANT);
    handleClose();
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid md={8} sm={11} xs={11}>
        <h1 className={classes.rootText}>
          <Typography variant="h6" className={classes.paragraph}>
            {intl.formatMessage(messages.login_method)}
          </Typography>
        </h1>
      </Grid>

      <Grid>
        <Card className={classes.card}>
          <CardActionArea
            className={classes.cardAction}
            onClick={handleManager}
          >
            <Typography variant="h6" className={classes.cardText}>
              {intl.formatMessage(messages.loginManager)}
            </Typography>
          </CardActionArea>
        </Card>

        <Card className={classes.card}>
          <CardActionArea
            className={classes.cardAction}
            onClick={handleApplicant}
          >
            <Typography variant="h6" className={classes.cardText}>
              {intl.formatMessage(messages.loginApplicant)}
            </Typography>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};
