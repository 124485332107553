import { useIntl } from 'react-intl';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import messages from 'messages';
import { Inbox } from 'components/Inbox/Inbox';

export const path = '/inbox';

export const component = function Messages() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.inbox)}>
      <Inbox />
    </PageWithDrawer>
  );
};
