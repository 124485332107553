import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import {
  Paper,
  Popper,
  Grow,
  Button,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchPDFById, fetchCSVByIds } from 'api';
import { SnackbarVariant } from '../../constants';
import messages from 'messages';

const useStyles = makeStyles(() => ({
  exportButton: {
    padding: '4px 15px',
    marginRight: '1rem',
    marginLeft: '3rem',
  },
  popper: {
    zIndex: 1,
  },
}));

export const ExportMenu = ({ title, id, rootClass }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const exportPDF = () => {
    fetchPDFById(id)
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const a = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = `${title}-${id}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.form_export_failed), {
          variant: SnackbarVariant.ERROR,
        });
      });
  };

  const exportCSV = () => {
    fetchCSVByIds([id])
      .then((response) => {
        const file = new Blob([response.data], {
          type: 'text/csv;charset=utf-8;',
        });
        const a = document.createElement('a');
        a.style = 'display: none';

        document.body.appendChild(a);
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = `${title}-${id}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.form_export_failed), {
          variant: SnackbarVariant.ERROR,
        });
      });
    handleExportMenuClose();
  };

  const handleToggleExportMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleExportMenuClose = () => {
    setMenuOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  return (
    <div className={rootClass}>
      <Button
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleExportMenu}
        variant="contained"
        color="primary"
        size="small"
        className={classes.exportButton}
      >
        {intl.formatMessage(messages.export)}
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={exportPDF}>
                    PDF-{intl.formatMessage(messages.as_file)}
                  </MenuItem>
                  <MenuItem onClick={exportCSV}>
                    CSV-{intl.formatMessage(messages.as_file)}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
