import { useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import useLocale from '@super-template/core/src/locale/useLocale';
import './main.css';

export default function Theme({ locale, children }) {
  const [localeSetting] = useLocale();
  locale ??= localeSetting;

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiTypography: {
            defaultProps: {
              variantMapping: {
                h5: 'h5',
                h6: 'h6',
                body1: 'p',
              },
            },
          },
        },
        palette: {
          mode: 'light',
          primary: {
            main: '#10285E',
            secondary: '#DB7900',
          },
          hallinto: {
            blueDark: '#10285E',
            blue: '#31446F',
            blueLight: '#697590',
            violet: '#B483AC',
            pink: '#DBC2D7',
            orange: '#DB7900',
            orangeDark: '#FF8C00',
            orangeLight: '#FFBA66',
            orangeBorder: '#DB7900',
            yellow: '#FDD29D',
            yellowLight: '#FFE8CC',
          },
          common: {
            black: '#191919',
            white: '#fff',
            gray: '#D7D7D7',
            greyLight: '#F3F4F7',
          },
          gray: '#D7D7D7',
        },
        typography: {
          h1: {
            fontSize: '2rem',
            color: '#253A79',
            fontWeight: 600,
            letterSpacing: 'initial',
          },
          h2: {
            fontSize: '1.725rem',
            color: '#253A79',
            fontWeight: 600,
            letterSpacing: 'initial',
          },
          h3: {
            fontSize: '1.5rem',
            color: '#253A79',
            fontWeight: 600,
            letterSpacing: 'initial',
          },
        },
        styleOverrides: {
          MuiCssBaseline: {
            '@global': {
              html: {
                backgroundColor: '#eaeaea',
                height: '100vh',
              },
              body: {
                height: '100%',
                backgroundColor: '#eaeaea',
              },
            },
          },
          MuiAlert: {
            message: {
              width: '100%',
            },
          },
          MuiButton: {
            contained: {
              padding: '10px 40px',
              borderRadius: 0,
              boxShadow: '1px 2px 4px rgba(0,0,0,0.2)',
              backgroundColor: '#10285E',
              border: 'solid 2px #10285E',
              '&:focus': {
                border: 'solid 2px #BAD3E0',
                boxShadow: 'none',
              },
              '&:hover': {
                backgroundColor: '#D7D7D7',
                boxShadow: 'none',
              },
              '&:active': {
                backgroundColor: '#10285E',
                boxShadow: 'inset 1px 2px 4px rgba(0,0,0,0.25)',
              },
            },
          },
          MuiSearchIcon: {
            searchIcon: {
              viewBox: '0px 0px 20px 20px',
            },
          },
          MuiListItem: {
            secondaryAction: {
              paddingRight: '130px',
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
