import { create as createAxios } from 'axios';
import {
  KEIKKALAISTUNNUSTILAUS,
  TUNNUSTILAUS,
} from './components/Form/FormTypes.js';

// API ENDPOINT

// For convenience, create an Axios instance with the baseURL for API requests.
export const axios = createAxios({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const createUser = async (user) => {
  return axios.post('/user', user);
};

export const acceptPermissions = async (user) => {
  return axios.put('/user/accept-permissions', user);
};

export const revokePermissions = async (user) => {
  return axios.put('/user/revoke-permissions', user);
};

export const fetchUserPermissionsAcceptedDate = async (user) => {
  return axios.get('/user/accepted-permissions-date', user);
};

export const fetchPin = async (pin) => {
  return axios.get(`/organization/${pin}`);
};

export const verifyPinForForm = async (password, formType) => {
  return axios.get(`/organization/verifyPinForForm/${password}/${formType}`);
};

export const updateCodeset = async () => {
  return axios.get('/organization/updateCodeset');
};

export const fetchFormResponses = async () => {
  return axios.get('/form-response');
};

export const submitFormResponse = async (form) => {
  return axios.post('/form-response', { ...form });
};

export const updateFormResponseList = async (forms) => {
  return axios.put('/form-response', forms);
};

export const updateFormResponse = async (form) => {
  return axios.put('/form-response/update', form);
};

export const fetchPDFById = async (id) => {
  return axios.get(`/form-response/pdf/${id}`, { responseType: 'blob' });
};

export const fetchCSVByIds = async (idList) => {
  return axios.post('/form-response/csv/list', idList, {
    responseType: 'csv',
  });
};

export const sendCredentialApplicationToEnsemble = async (form, formType) => {
  switch (formType) {
    case KEIKKALAISTUNNUSTILAUS:
      return axios.post('/form-response/external-credential-application', form);

    case TUNNUSTILAUS:
    default:
      return axios.post('/form-response/credential-application', form);
  }
};

export const updateExpirationDate = async (expirationDateInfo) => {
  return axios.put('/account-renewal/update-expiration', {
    ...expirationDateInfo,
  });
};

export const fetchOrganizationList = async () => {
  return axios.get('/organizations');
};

export const fetchOrganization = async (id) => {
  return axios.get(`/organizations/${id}`);
};

export const createOrganization = async (organization) => {
  return axios.post('/organizations', {
    ...organization,
  });
};

export const updateOrganization = async (organization, id) => {
  return axios.put(`/organizations/${id}`, {
    ...organization,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteOrganization = async (id) => {
  return axios.delete(`/organizations/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const fetchAllSections = async () => {
  return axios.get(`/section/get-all`);
};

export const fetchAllSectionsForManager = async () => {
  return axios.get(`/section/manager/get-all`);
};

export const fetchSectionList = async (organizationId) => {
  return axios.get(`/section/get-all/${organizationId}`);
};

export const fetchSection = async (sectionId) => {
  return axios.get(`/section/${sectionId}`);
};

export const getCostCentres = async () => {
  return axios.get('/section/get-cost-centres');
};

export const getTitles = async () => {
  return axios.get('/section/get-titles');
};

export const createSection = async (section) => {
  return axios.post('/section', {
    ...section,
  });
};

export const updateSection = async (section, id) => {
  return axios.put(`/section/${id}`, {
    ...section,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteSection = async (id) => {
  return axios.delete(`/section/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const fetchManagers = async (sectionId) => {
  return axios.get(`/section/managers/${sectionId}`);
};

export const fetchAllManagers = async () => {
  return axios.get(`/manager/allManagers`);
};

export const createManager = async (manager) => {
  return axios.post('/manager', {
    ...manager,
  });
};

export const updateManager = async (manager, id) => {
  return axios.put(`/manager/${id}`, {
    ...manager,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteManager = async (managerId, sectionId) => {
  return axios.delete(`/manager/${managerId}/${sectionId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getOrganizationsForManager = async () => {
  return axios.get('/organizations/manager');
};

export const getOrganizationForManager = async (id) => {
  return axios.get(`/organizations/manager/${id}`);
};

export const getSectionsForManager = async (orgId) => {
  return axios.get(`/section/manager/get-all/${orgId}`);
};

export const getSectionForManager = async (id) => {
  return axios.get(`/section/manager/${id}`);
};

export const fetchApplicants = async (sectionId) => {
  return axios.get(`/section/applicants/${sectionId}`);
};

export const fetchAllApplicants = async () => {
  return axios.get(`/applicant/allApplicants`);
};

export const fetchAllApplicantsForManager = async () => {
  return axios.get(`/applicant/manager/allApplicants`);
};

export const createApplicant = async (applicant) => {
  return axios.post('/applicant', {
    ...applicant,
  });
};

export const updateApplicant = async (applicant, id) => {
  return axios.put(`/applicant/${id}`, {
    ...applicant,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteApplicant = async (id, sectionId) => {
  return axios.delete(`/applicant/${id}/${sectionId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const uploadApplicantsCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/applicant/upload-csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadManagersCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/manager/upload-csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadSectionsCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/section/upload-csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUserStatus = async () => {
  return axios.get(`/user/user-status`);
};

export const getSectionsForApplicant = async () => {
  return axios.get('/section/applicant');
};

export const getOrganizationsForApplicant = async () => {
  return axios.get('/organizations/applicant');
};

export const getApplicant = async () => {
  return axios.get('/applicant/applicant');
};

export const getApplicantDates = async () => {
  return axios.get('/applicant/applicantDates');
};

export const getMessages = async () => {
  return axios.get('/message');
};

export const messageRead = async (id) => {
  return axios.post(`/message/update-message/${id}`);
};
