import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Button, Typography } from '@material-ui/core';
import { format, add, parseISO, isWithinInterval } from 'date-fns';
import { FormDatePicker } from './FormDatePicker.js';
import { makeStyles } from '@material-ui/core/styles';
import { updateExpirationDate } from '../../../api.js';
import { useHistory } from 'react-router-dom';

import messages from 'messages.js';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    margin: '1rem 0',
    '& > *': {
      marginBottom: '1rem',
      width: '25ch',
    },
  },
  paragraph: {
    margin: '0.5rem 0',
  },
}));

export const RenewalFormView = ({ formValues }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const dateNow = Date.now();
  const formattedDateNow = format(dateNow, 'yyyy-MM-dd');
  const maxDate = add(dateNow, { months: 13 });
  const formattedMaxDate = format(maxDate, 'yyyy-MM-dd');
  const [newExpirationDate, setNewExpirationDate] = useState(formattedDateNow);
  const [showError, setShowError] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleOnChange = (e) => {
    setShowError(false);
    if (
      isWithinInterval(parseISO(e.target.value), {
        start: dateNow,
        end: maxDate,
      })
    ) {
      setNewExpirationDate(e.target.value);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWaiting(true);
    updateExpirationDate({ sub: formValues.sub, expires: newExpirationDate })
      .then(() => {
        history.replace('/renewalform/success');
      })
      .catch(() => {
        setWaiting(false);
        enqueueSnackbar(intl.formatMessage(messages.general_error), {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Typography color="initial" variant="h1">
        {intl.formatMessage(messages.form_account_renewal)}
      </Typography>

      <Typography color="initial" variant="body1" className={classes.paragraph}>
        {intl.formatMessage(messages.form_account_renewal_desc)}
      </Typography>

      <form className={classes.root} noValidate autoComplete="off">
        <FormDatePicker
          newExpirationDate={newExpirationDate}
          maxDate={formattedMaxDate}
          dateNow={formattedDateNow}
          handleOnChange={handleOnChange}
          showError={showError}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={showError || waiting}
          onClick={(e) => handleSubmit(e)}
        >
          {intl.formatMessage(messages.send)}
        </Button>
      </form>
    </>
  );
};
