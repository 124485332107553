import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'messages';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import { FormView } from 'components/Form';
import { RuntimeConfigProvider } from '@super-template/core/src/runtimeConfig/index.js';
import runtimeConfigDefaults from '../../../runtimeConfigDefaults';

export const path = '/form/:id';

export const component = function Form() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.forms)}>
      <RuntimeConfigProvider defaults={runtimeConfigDefaults}>
        <FormView />
      </RuntimeConfigProvider>
    </PageWithDrawer>
  );
};
