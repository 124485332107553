import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import messages from 'messages';
import PageWithDrawer from '../../layouts/PageWithDrawer';
import { getUserStatus } from 'userUtils';
import { SectionList } from 'components/ListView/Lists/SectionList';

export const path = '/listview/:orgId';

export const component = function SectionListView() {
  const intl = useIntl();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const userStatus = getUserStatus();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  if (!hasOrganizationAdminRole && userStatus !== 'manager') {
    history.push('/');
    return <></>;
  }

  return (
    <PageWithDrawer title={intl.formatMessage(messages.home)}>
      <div align="right">
        <SectionList />
      </div>
    </PageWithDrawer>
  );
};
