import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  footer: {
    bottom: '0',
    marginTop: '3vh',
    fontSize: '0.8rem',
    background: theme.palette.common.white,
    '& > * ': {
      [theme.breakpoints.up('lg')]: {
        margin: '1.5rem',
      },
      [theme.breakpoints.up('md')]: {
        margin: '1rem',
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '.5rem 0',
    },
    minHeight: '10vh',
  },
  box: {
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid gray',
      paddingLeft: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0',
    },
  },
  links: {
    minWidth: '10rem',
    '& > a': {
      display: 'block',
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        padding: '.5rem',
      },
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.footer}
      role="contentinfo"
    >
      <Grid item md={1} sm={5} xs={10} className={classes.links}>
        <Link
          to={{
            pathname: 'https://pirha.fi/saavutettavuusseloste',
          }}
          target="_blank"
        >
          {intl.formatMessage(messages.footer_accessability)}
        </Link>
        <Link
          to={{
            pathname: 'https://pirha.fi/tietosuoja/sahkoinen-asiointi',
          }}
          target="_blank"
        >
          {intl.formatMessage(messages.footer_terms)}
        </Link>
        <Link
          to={{
            pathname: 'https://www.pirha.fi/sidosryhmaportaalin-kayttoehdot',
          }}
          target="_blank"
        >
          {intl.formatMessage(messages.terms)}
        </Link>
      </Grid>
      <Grid item md={2} sm={5} xs={10} className={classes.box}>
        <p>
          <FormattedMessage
            defaultMessage="Jos sinulla on kysyttävää lomakkeista, ota yhteyttä {palautelomake} kautta."
            values={{
              palautelomake: (
                <a
                  href="https://asiakaspalaute.pirha.fi/AnalysticaStart/Pirha.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  palautelomakkeen
                </a>
              ),
            }}
          />
        </p>
      </Grid>
      <Grid item md={2} sm={5} xs={10} className={classes.box}>
        <p>
          <FormattedMessage
            defaultMessage="Jos huomaat palvelussa teknisen virheen, täytä ystävällisesti {vikailmoitus}. Palvelua koskevat kehitysideat ja palautteet voit ilmoittaa {palautelomake}."
            values={{
              vikailmoitus: (
                <a
                  href="https://q.surveypal.com/Sidosryhmat-vikailmoitus"
                  target="_blank"
                  rel="noreferrer"
                >
                  vikailmoituslomake
                </a>
              ),

              palautelomake: (
                <a
                  href="https://asiakaspalaute.pirha.fi/AnalysticaStart/Pirha.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  palautelomakkeella
                </a>
              ),
            }}
          />
        </p>
      </Grid>
      <Grid item md={2} sm={5} xs={10} className={classes.box}>
        &copy; Pirkanmaan hyvinvointialue <br /> PL 272, 33101 Tampere
      </Grid>
    </Grid>
  );
};

export default Footer;
