import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import cuid from 'cuid';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import ListAlt from '@material-ui/icons/ListAlt';

import { useKeycloak } from '@react-keycloak/web';
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';

import messages from 'messages.js';
import { fetchManagers, deleteManager } from 'api';
import DeleteButton from '../DeleteButton';
import OrgAdminActionDialog from 'components/OrgAdminActionDialog/OrgAdminActionDialog';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    height: '50px',
    background: theme.palette.common.greyLight,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  tabletitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  sectiontable: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  tableRow: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
  tableCell: {
    padding: '10px 10px',
  },
  tableButton: {
    color: theme.palette.hallinto.blueDark,
  },
  actionFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ManagerHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.tableHeader}>
      <Typography variant="h2" color="primary" className={classes.tabletitle}>
        <ListAlt className={classes.icon} />
        {intl.formatMessage(messages.manager_title)}
      </Typography>
    </div>
  );
};

export const ManagerList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');
  const { sectionId } = useParams();
  const location = useLocation();
  const [managerList, setManagerList] = useState([]);
  const [filteredManagerList, setFilteredManagerList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [dialogType, setDialogType] = useState('');
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    getManagerList();
    // eslint-disable-next-line
  }, [location]);

  const getManagerList = () => {
    fetchManagers(sectionId).then((response) => {
      setManagerList(response.data);
      setFilteredManagerList(response.data);
    });
  };

  const handleDelete = (id) => {
    const indexInManagerList = managerList.findIndex((item) => item.id === id);

    if (indexInManagerList > -1) {
      deleteManager(managerList[indexInManagerList].id, sectionId)
        .then(() => {
          const newManagerList = [...managerList];
          newManagerList.splice(indexInManagerList, 1);
          setManagerList(newManagerList);

          const indexInFilteredManagerList = filteredManagerList.findIndex(
            (item) => item.id === id
          );
          if (indexInFilteredManagerList > -1) {
            const newFilteredManagerList = [...filteredManagerList];
            newFilteredManagerList.splice(indexInFilteredManagerList, 1);
            setFilteredManagerList(newFilteredManagerList);
          }
        })
        .catch(() => {});
    }
  };

  const handleEditClick = (organization) => {
    setSelectedManager(organization);
    setDialogType('manager');
    setDialogOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedManager(null);
    setDialogType('manager');
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={classes.sectiontable}>
        <div className={classes.searchContainer}></div>
        <ManagerHeader />
        <TableContainer>
          <Table className={classes.sectiontable}>
            <TableBody>
              {filteredManagerList
                .slice((page - 1) * PER_PAGE, page * PER_PAGE)
                .map((manager) => (
                  <TableRow className={classes.tableRow} key={cuid()}>
                    {manager.employeeNumber ? (
                      <TableCell className={classes.tableCell}>
                        {manager.employeeNumber}
                      </TableCell>
                    ) : null}

                    {manager.socialSecurityNumber ? (
                      <TableCell className={classes.tableCell}>
                        {manager.socialSecurityNumber}
                      </TableCell>
                    ) : null}

                    <TableCell className={classes.tableCell}>
                      {manager.firstName}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {manager.lastName}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {manager.email}
                    </TableCell>
                    {hasOrganizationAdminRole && (
                      <TableCell className={classes.tableCell}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleEditClick(manager)}
                        >
                          {intl.formatMessage(messages.general_edit)}
                        </Button>
                        <DeleteButton
                          id={manager.id}
                          handleDeleteCallback={handleDelete}
                          className={classes.button}
                        >
                          {intl.formatMessage(messages.general_delete)}
                        </DeleteButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.actionFooter}>
          <Pagination
            count={Math.ceil(filteredManagerList.length / PER_PAGE)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
          <ButtonGroup>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setDialogType('csv');
                setDialogOpen(true);
              }}
            >
              {intl.formatMessage(messages.export_external_csv)}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCreateClick}
            >
              {intl.formatMessage(messages.add)}
            </Button>
          </ButtonGroup>
        </div>
        <OrgAdminActionDialog
          dialogType={dialogType}
          open={dialogOpen}
          uploadType={'manager'}
          handleClose={handleCloseDialog}
          manager={selectedManager}
          sectionId={sectionId}
          isEditing={selectedManager !== null}
          updateListCallback={() => getManagerList()}
        />
      </div>
    </ErrorBoundary>
  );
};
