import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import IntlMessagesProvider from '@super-template/core/src/IntlMessagesProvider';
import Theme from '@super-template/theme';
import AuthProvider from '@super-template/axios-keycloak-react-auth-integration';
import ServiceProvider from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Routes from './Routes';
import { LinearProgress, Zoom } from '@material-ui/core';
import PageWithDrawer from './layouts/PageWithDrawer';
import { Layout } from '@super-template/core/src/LayoutFallback';
import FormulaConfig from './FormulaConfig';
import Login from './Routes/Login';
import { component as Registration } from './Routes/Registration';
import { SnackbarProvider } from 'notistack';
import { RuntimeConfigProvider } from '@super-template/core/src/runtimeConfig';
import runtimeConfigDefaults from 'runtimeConfigDefaults';
import { MessageProvider } from 'contexts/MessageProvider';

export default function App() {
  return (
    <Theme>
      <Suspense fallback={<LinearProgress />}>
        <RuntimeConfigProvider defaults={runtimeConfigDefaults}>
          <AuthProvider>
            <IntlMessagesProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Layout FallbackComponent={PageWithDrawer}>
                  <FormulaConfig>
                    <BrowserRouter>
                      <SnackbarProvider
                        maxSnack={3}
                        hideIconVariant
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        TransitionComponent={Zoom}
                        autoHideDuration={
                          runtimeConfigDefaults.snackbarDuration
                        }
                      >
                        <ServiceProvider
                          registration={<Registration />}
                          fallback={<Login />}
                        >
                          <MessageProvider>
                            <QueryParamProvider ReactRouterRoute={Route}>
                              <Routes />
                            </QueryParamProvider>
                          </MessageProvider>
                        </ServiceProvider>
                      </SnackbarProvider>
                    </BrowserRouter>
                  </FormulaConfig>
                </Layout>
              </ErrorBoundary>
            </IntlMessagesProvider>
          </AuthProvider>
        </RuntimeConfigProvider>
      </Suspense>
    </Theme>
  );
}
