import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'messages';

import PageWithDrawer from 'components/layouts/PageWithDrawer';
import { Form } from 'components/FormEdit';

export const path = '/edit/:formId/:id/:responseId';

export const component = function EditForm() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.forms)}>
      <Form />
    </PageWithDrawer>
  );
};
