import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Pagination } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import cuid from 'cuid';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';

import { useKeycloak } from '@react-keycloak/web';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
} from '@material-ui/core';

import messages from 'messages.js';
import DeleteButton from '../DeleteButton';
import Search from '../Search';
import OrgAdminActionDialog from 'components/OrgAdminActionDialog/OrgAdminActionDialog';
import {
  deleteOrganization,
  fetchOrganizationList,
  getOrganizationsForManager,
} from '../../../api.js';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    background: theme.palette.common.greyLight,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  title: {
    marginLeft: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  list: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  listItem: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
  button: {
    color: theme.palette.hallinto.blueDark,
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  },
  actionFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const OrganizationList = () => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  const [organizationList, setOrganizationList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line
  }, []);

  const fetchOrganizations = () => {
    const fetchOrgsMethod = hasOrganizationAdminRole
      ? fetchOrganizationList
      : getOrganizationsForManager;

    fetchOrgsMethod().then((response) => {
      setOrganizationList(response.data);
    });
  };

  const handleDelete = (id) => {
    deleteOrganization(id).then(() => {
      fetchOrganizations();
    });
  };

  const handleEditClick = (organization) => {
    setSelectedOrganization(organization);
    setDialogOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedOrganization(null);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={classes.list}>
        <div className={classes.searchContainer}>
          <Search />
        </div>
        <div className={classes.listHeader}>
          <Typography variant="h2" color="primary" className={classes.title}>
            <ListAltIcon className={classes.icon} />
            {intl.formatMessage(messages.organizations_title)}
          </Typography>
        </div>
        <List>
          {organizationList
            .slice((page - 1) * PER_PAGE, page * PER_PAGE)
            .map((organization) => (
              <ListItem
                className={classes.listItem}
                key={cuid()}
                onClick={() => history.push(`/listview/${organization.id}`)}
              >
                <ListItemText>{organization.organizationName}</ListItemText>
                {hasOrganizationAdminRole && (
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEditClick(organization)}
                    >
                      {intl.formatMessage(messages.general_edit)}
                    </Button>
                    <DeleteButton
                      id={organization.id}
                      handleDeleteCallback={handleDelete}
                      className={classes.button}
                    >
                      {intl.formatMessage(messages.general_delete)}
                    </DeleteButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
        <div className={classes.actionFooter}>
          <Pagination
            count={Math.ceil(organizationList.length / PER_PAGE)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleCreateClick}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        </div>
        <OrgAdminActionDialog
          dialogType="organization"
          open={dialogOpen}
          handleClose={handleCloseDialog}
          organization={selectedOrganization}
          isEditing={selectedOrganization !== null}
          updateListCallback={fetchOrganizations}
        />
      </div>
    </ErrorBoundary>
  );
};
