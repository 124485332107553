import { HasRole } from '@postinumero/react-auth';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Assignment,
  Extension,
  Link as LinkIcon,
  Home,
} from '@material-ui/icons';
import List from '@material-ui/core/List';
import {
  Card,
  Box,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { path as admin } from 'components/Routes/Admin';
import { path as home } from 'components/Routes/Home';
import { path as forms } from 'components/Routes/Forms';
import { useIntl } from 'react-intl';

import messages from 'messages';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

const useStyles = makeStyles((theme) => ({
  buttonLink: {
    textDecoration: 'none',
  },
  navList: {
    paddingTop: 0,
  },
  navCard: {
    display: 'flex',
  },
  cardText: {
    margin: 'auto 1rem',
    textDecoration: 'none',
  },
  drawerRoot: {
    marginBottom: theme.spacing(1),
    background: theme.palette.common.white,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.hallinto.yellowLight,
    },
  },
  icon: {
    background: theme.palette.hallinto.orange,
    color: theme.palette.common.white,
  },
  activeRoot: {
    marginBottom: theme.spacing(1),
    background: theme.palette.hallinto.yellow,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.hallinto.yellowLight,
    },
    '& $icon': {
      background: theme.palette.common.white,
      color: theme.palette.hallinto.orange,
    },
  },
  rounding: {
    borderRadius: '8px',
  },
}));

export default function Drawer({ title }) {
  const classes = useStyles();

  const intl = useIntl();

  return (
    <div role="navigation">
      <List className={classes.navList}>
        <Box boxShadow={2} component="li" className={classes.rounding}>
          <Card
            className={classes.buttonLink}
            component={Link}
            to={home}
            raised={true}
          >
            <CardActionArea
              data-type={
                title === intl.formatMessage(messages.home)
                  ? 'active'
                  : 'not-active'
              }
              className={
                title === intl.formatMessage(messages.home)
                  ? classes.activeRoot
                  : classes.drawerRoot
              }
            >
              <CardContent className={classes.navCard}>
                <Avatar className={classes.icon}>
                  <Home />
                </Avatar>
                <Typography variant="button" className={classes.cardText}>
                  {intl.formatMessage(messages.home)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box boxShadow={2} component="li" className={classes.rounding}>
          <Card className={classes.buttonLink} component={Link} to={forms}>
            <CardActionArea
              data-type={
                title === intl.formatMessage(messages.forms)
                  ? 'active'
                  : 'not-active'
              }
              className={
                title === intl.formatMessage(messages.forms)
                  ? classes.activeRoot
                  : classes.drawerRoot
              }
            >
              <CardContent className={classes.navCard}>
                <Avatar className={classes.icon}>
                  <Assignment />
                </Avatar>
                <Typography
                  variant="button"
                  className={classes.cardText}
                  data-type={'card-text'}
                >
                  {intl.formatMessage(messages.forms)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box boxShadow={2} component="li" className={classes.rounding}>
          <Card
            className={classes.buttonLink}
            component={Link}
            to={{ pathname: runtimeConfig.bcb_psychotherapy_link }}
            target="_blank"
          >
            <CardActionArea
              className={classes.drawerRoot}
              data-type={'not-active'}
            >
              <CardContent className={classes.navCard}>
                <Avatar className={classes.icon}>
                  <LinkIcon />
                </Avatar>
                <Typography variant="button" className={classes.cardText}>
                  {intl.formatMessage(messages.link_psychotherapy)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <HasRole resource={{ account: 'admin' }}>
          <Box boxShadow={2} component="li" className={classes.rounding}>
            <Card component={Link} to={admin}>
              <CardActionArea className={classes.drawerRoot}>
                <CardContent className={classes.navCard}>
                  <Avatar>
                    <Extension />
                  </Avatar>
                  <Typography variant="button" className={classes.cardText}>
                    {intl.formatMessage(messages.admin)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        </HasRole>
      </List>
    </div>
  );
}
