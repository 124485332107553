import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';
import { fi } from 'date-fns/locale';
import { ErrorBoundary } from 'react-error-boundary';
import Formula, { useForm } from '@visma/formula';
import { makeStyles } from '@material-ui/core/styles';

import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

import { updateFormResponse } from 'api';
import messages from 'messages';
import { ExportMenu } from '../ExportMenu';
import { SnackbarVariant } from '../../constants';

const useStyles = makeStyles(() => ({
  exportMenu: {
    marginTop: '.5rem',
  },
}));

export const Form = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { formId, id, responseId } = useParams();
  const { token } = useKeycloak().keycloak;
  const form = useForm(formId);

  const formRef = useRef();

  const handlePostSubmit = (formResponseId) => {
    const formResponse = {
      id: id,
      formId: formId,
      formulaResponseId: formResponseId,
      updateDateTime: new Date(),
    };

    updateFormResponse(formResponse)
      .then(() => {
        history.push('/');
        enqueueSnackbar(intl.formatMessage(messages.form_submitted), {
          variant: SnackbarVariant.SUCCESS,
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.form_submit_failed), {
          variant: SnackbarVariant.ERROR,
        });
      });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Formula
        ref={formRef}
        axios={(axios) => {
          axios.defaults.baseURL = runtimeConfig.formulaUrl;
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }}
        id={Number(formId)}
        onPostSubmit={handlePostSubmit}
        dataId={responseId}
        confirm={false}
        dateFnsLocale={fi}
      />
      <ExportMenu
        title={form.title}
        id={responseId}
        rootClass={classes.exportMenu}
      />
    </ErrorBoundary>
  );
};
