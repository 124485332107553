import { useIntl } from 'react-intl';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { createSection, updateSection, getCostCentres, getTitles } from 'api';
import messages from 'messages';
import { externalOptions, SnackbarVariant } from '../../../constants';
import { csvToJson } from 'csvUtils/csvToJson';

const DEFAULT_SECTION = {
  sectionName: '',
  serviceCode: '',
  costCentre: '',
  title: '',
  externalType: '',
  organizationId: '',
};

export default function SectionsForm({
  section,
  isEditing,
  handleClose,
  updateListCallback,
}) {
  const currentSection = section ? section : DEFAULT_SECTION;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { orgId } = useParams();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [costCentreOptions, setCostCentreOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);

  const [formError, setFormError] = useState({
    serviceCodeError: false,
    externalTypeError: true,
  });

  const [formState, setFormState] = useState({
    id: currentSection.id,
    sectionName: currentSection.sectionName,
    serviceCode: currentSection.serviceCode,
    costCentre: currentSection.costCentre,
    title: currentSection.title,
    externalType: currentSection.externalType,
    organizationId: orgId,
  });

  useEffect(() => {
    getCostCentres().then((costCentres) => {
      if (costCentres) {
        costCentres = csvToJson(costCentres.data);
        setCostCentreOptions(costCentres);
      }
    });

    getTitles().then((titles) => {
      if (titles) {
        titles = csvToJson(titles.data);
        setTitleOptions(titles);
      }
    });
  }, []);

  useEffect(() => {
    const serviceCodeIsValid = !/^[A-Z]{4}[0-9]{4}$/.test(
      formState.serviceCode
    );
    setFormError({ ...formError, serviceCode: serviceCodeIsValid });

    if (
      [
        formState.sectionName,
        formState.serviceCode,
        formState.costCentre,
        formState.title,
        formState.externalType,
        formState.organizationId,
      ].every((x) => x !== '') &&
      !serviceCodeIsValid
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
    // eslint-disable-next-line
  }, [formState]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'serviceCode') {
      setFormState({ ...formState, [name]: value.toUpperCase() });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const editSection = () => {
    if (!disableSubmit) {
      updateSection(formState, formState.id)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              enqueueSnackbar(intl.formatMessage(messages.section_exists), {
                variant: SnackbarVariant.ERROR,
              });
            } else {
              enqueueSnackbar(intl.formatMessage(messages.general_error), {
                variant: SnackbarVariant.ERROR,
              });
            }
          }
        });
    }
  };

  const createNewSection = () => {
    if (!disableSubmit) {
      createSection(formState)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              enqueueSnackbar(intl.formatMessage(messages.section_exists), {
                variant: SnackbarVariant.ERROR,
              });
            } else {
              enqueueSnackbar(intl.formatMessage(messages.general_error), {
                variant: SnackbarVariant.ERROR,
              });
            }
          }
        });
    }
  };

  const dialogContentText = isEditing
    ? messages.section_edit
    : messages.section_create;

  const actionButtonOnClick = isEditing ? editSection : createNewSection;

  const actionButtonLabel = intl.formatMessage(
    isEditing ? messages.send : messages.send
  );

  const renderAutocomplete = (value, options, label, name) => (
    <Autocomplete
      disablePortal
      value={value}
      options={options}
      fullWidth
      onChange={(event, newValue) => {
        setFormState({ ...formState, [name]: newValue });
      }}
      renderInput={(params) => (
        <TextField
          required
          variant="standard"
          {...params}
          label={label}
          name={name}
        />
      )}
    />
  );

  return (
    <>
      <DialogTitle id="confirm-dialog-title">
        <Typography component="h3">
          {intl.formatMessage(messages.section)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <Typography component="subtitle2">
            {intl.formatMessage(dialogContentText)}
          </Typography>
        </DialogContentText>
        <form>
          <TextField
            required
            variant="standard"
            name="sectionName"
            label="Osa-alueen nimi"
            fullWidth
            inputProps={{ maxLength: 250 }}
            value={formState.sectionName}
            onChange={handleChange}
          />
          <TextField
            required
            variant="standard"
            name="serviceCode"
            label="Palvelukoodi"
            fullWidth
            value={formState.serviceCode}
            onChange={handleChange}
            inputProps={{ maxLength: 8 }}
            error={formError.serviceCode && formState.serviceCode !== ''}
            helperText={
              formError.serviceCode && formState.serviceCode !== ''
                ? 'Palvelukoodin tulee olla 4 isoa kirjainta ja 4 numeroa (esim. ABCD1234)'
                : ''
            }
          />
          {renderAutocomplete(
            formState.costCentre,
            costCentreOptions,
            'Kustannuspaikka',
            'costCentre'
          )}
          {renderAutocomplete(
            formState.title,
            titleOptions,
            'Ammattinimike',
            'title'
          )}
          {renderAutocomplete(
            formState.externalType,
            externalOptions,
            'Käyttäjätyyppi',
            'externalType'
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {intl.formatMessage(messages.back)}
        </Button>
        <Button
          disabled={disableSubmit}
          variant="contained"
          size="small"
          color="primary"
          onClick={actionButtonOnClick}
        >
          {actionButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
}
