import { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { uploadSectionsCSV, uploadManagersCSV, uploadApplicantsCSV } from 'api';
import messages from 'messages';
import { SnackbarVariant } from '../../constants';

function FileInput({ accept, onChange }) {
  return <input hidden type={'file'} accept={accept} onChange={onChange} />;
}

function SubmitButton({ onClick, label }) {
  return (
    <Button onClick={onClick} variant="string">
      {label}
    </Button>
  );
}

function SendButtons({ children }) {
  return <ButtonGroup>{children}</ButtonGroup>;
}

export default function CsvImport({
  handleClose,
  uploadType,
  updateListCallback,
}) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e, uploadFunction) => {
    e.preventDefault();
    if (file) {
      uploadFunction(file)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              enqueueSnackbar(
                intl.formatMessage(messages.csv_import_error_exists),
                {
                  variant: SnackbarVariant.ERROR,
                }
              );
            } else if (
              error.response.status === 400 ||
              error.response.status === 404
            ) {
              enqueueSnackbar(
                intl.formatMessage(messages.csv_import_error_check_csv),
                {
                  variant: SnackbarVariant.ERROR,
                }
              );
            } else {
              enqueueSnackbar(
                intl.formatMessage(messages.csv_import_error_generic),
                {
                  variant: SnackbarVariant.ERROR,
                }
              );
            }
          }
        });
    }
  };

  return (
    <>
      <div style={{ textAlign: 'center', paddingBottom: '2rem' }}>
        <h1>{intl.formatMessage(messages.import_csv)}</h1>
        <form>
          <SendButtons>
            <Button variant="contained" component="label">
              <FormattedMessage defaultMessage="Lisää tiedosto" />
              <FileInput accept={'.csv'} onChange={handleOnChange} />
            </Button>

            {uploadType === 'section' && (
              <SubmitButton
                onClick={(e) => {
                  handleOnSubmit(e, uploadSectionsCSV);
                }}
                label={intl.formatMessage(messages.send)}
              />
            )}

            {uploadType === 'manager' && (
              <SubmitButton
                onClick={(e) => {
                  handleOnSubmit(e, uploadManagersCSV);
                }}
                label={intl.formatMessage(messages.send)}
              />
            )}
            {uploadType === 'applicant' && (
              <SubmitButton
                onClick={(e) => {
                  handleOnSubmit(e, uploadApplicantsCSV);
                }}
                label={intl.formatMessage(messages.send)}
              />
            )}
          </SendButtons>
        </form>
        {file && <h4>Tiedosto: {file.name}</h4>}
      </div>
      <Button onClick={handleClose} color="primary">
        {intl.formatMessage(messages.back)}
      </Button>
    </>
  );
}
