import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';

import { ServiceContext } from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';
import { useUserClaim } from '@super-template/axios-keycloak-react-auth-integration/src/useUserClaim';

import messages from 'messages';
import { Assent } from './Assent';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  root: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  infoTitle: {
    color: theme.palette.hallinto.blueDark,
  },
}));

export const ProfilePage = () => {
  const { keycloak } = useKeycloak();
  const { idTokenParsed } = keycloak;
  const intl = useIntl();
  const classes = useStyles();
  const ssn = useUserClaim('ssn');
  const city = useUserClaim('city');
  const postNumber = useUserClaim('postNumber');
  const streetAddress = useUserClaim('address');
  const serviceContext = useContext(ServiceContext);
  const { isAdmin } = serviceContext;

  const getAddress = () => {
    return streetAddress || (city && postNumber) ? (
      <Typography gutterBottom>
        {streetAddress}
        <br />
        {city} {postNumber}
      </Typography>
    ) : (
      <p>-</p>
    );
  };

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.title}>
        {intl.formatMessage(messages.profile_title)}
      </Typography>
      {idTokenParsed && (
        <Grid container direction="row" spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined" square>
              <Typography className={classes.infoTitle}>
                {intl.formatMessage(messages.name)}
              </Typography>
              <Typography gutterBottom>
                {idTokenParsed.given_name} {idTokenParsed.family_name}
              </Typography>
              <Typography className={classes.infoTitle}>
                {intl.formatMessage(messages.sso)}
              </Typography>
              <Typography gutterBottom>{ssn ? ssn : '-'}</Typography>
              <Typography className={classes.infoTitle}>
                {intl.formatMessage(messages.address)}
              </Typography>
              {getAddress()}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {!isAdmin && <Assent />}
          </Grid>
        </Grid>
      )}
    </>
  );
};
