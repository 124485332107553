import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: '.5rem 0',
    },
  },
  button: {
    width: '100%',
  },
}));

const RegistrationNav = ({
  handlePrevious,
  handleNext,
  nextText,
  previousText,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={4} md={10} sm={10} xs={10} className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handlePrevious}
        >
          {previousText}
        </Button>
      </Grid>
      <Grid item lg={4} md={10} sm={10} xs={10} className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleNext}
        >
          {nextText}
        </Button>
      </Grid>
    </>
  );
};

export default RegistrationNav;
