import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import messages from 'messages';
import { useIntl } from 'react-intl';

export default function DeleteButton(props) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    props.handleDeleteCallback(props.id);
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        name={props.id}
        onClick={handleClickOpen}
      >
        {intl.formatMessage(messages.general_delete)}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{intl.formatMessage(messages.confirm_dialog)}</DialogTitle>
        <DialogContent>
          {intl.formatMessage(messages.confirm_delete)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {intl.formatMessage(messages.back)}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {intl.formatMessage(messages.general_delete)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
