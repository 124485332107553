import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser } from '@postinumero/react-auth';

import { ServiceContext } from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';
import { useLogout } from '@super-template/axios-keycloak-react-auth-integration/src/api';

import TermsAndConditions from './RegistrationSteps/TermsAndConditions';
import ServiceAgreement from './RegistrationSteps/ServiceAgreement';
import Identify from './RegistrationSteps/Identify';
import { createUser, acceptPermissions } from 'api';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0),
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(0, 1, 2, 1),
    color: theme.palette.primary.hallintoBlueDark,
    fontWeight: '500',
    fontSize: '2.4em',
  },
  section: {
    padding: theme.spacing(0, 4, 2, 2),
  },
  paragraph: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.hallintoBlue,
  },
  borderContainer: {
    marginBottom: theme.spacing(4),
  },
  border: {
    height: '4px',
    background: theme.palette.gray,
  },
  borderActive: {
    height: '4px',
    background: theme.palette.primary.hallintoBlue,
  },
}));

export const RegistrationWizard = () => {
  const [step, setStep] = useState(0);
  const [currentUser] = useCurrentUser();
  const history = useHistory();
  const classes = useStyles();
  const logout = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const serviceContext = useContext(ServiceContext);
  const { setConcentStatus } = serviceContext;
  const intl = useIntl();

  const RegistrationComplete = () => {
    acceptPermissions(currentUser)
      .then(() => {
        setConcentStatus(true);
        history.push('/');
      })
      .catch((err) =>
        enqueueSnackbar(err, {
          variant: 'error',
        })
      );
  };

  const createUserNextStep = () => {
    createUser()
      .then(() => {
        setStep(step + 1);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          // User already exists
          setStep(step + 1);
        } else if (err.response.status === 401) {
          enqueueSnackbar(intl.formatMessage(messages.session_expired), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(intl.formatMessage(messages.user_creation_error), {
            variant: 'error',
          });
        }
      });
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      {step === 0 && (
        <>
          <Typography variant="h1" color="initial" className={classes.title}>
            {intl.formatMessage(messages.registration_title)}
          </Typography>
          <Identify
            handleNextStep={createUserNextStep}
            handlePreviousStep={logout}
            classes={classes}
          />
        </>
      )}
      {step === 1 && (
        <>
          <Typography variant="h1" color="initial" className={classes.title}>
            {intl.formatMessage(messages.registration_step_1_title)}
          </Typography>
          <TermsAndConditions
            handleNextStep={() => setStep(step + 1)}
            handlePreviousStep={logout}
            classes={classes}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Typography variant="h1" color="initial" className={classes.title}>
            {intl.formatMessage(messages.registration_step_2_title)}
          </Typography>
          <ServiceAgreement
            handleNextStep={RegistrationComplete}
            handlePreviousStep={logout}
            classes={classes}
          />
        </>
      )}
    </Paper>
  );
};
