import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';

import { fetchSection, getSectionForManager } from 'api';
import messages from 'messages.js';
import OrgAdminActionDialog from 'components/OrgAdminActionDialog/OrgAdminActionDialog';
import DescriptionField from '../DescriptionField';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    height: '30px',
    background: theme.palette.common.greyLight,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  tabletitle: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  sectiontable: {
    backgroundColor: theme.palette.common.white,
  },
  editButton: {
    margin: theme.spacing(0.5),
    padding: '3px 5px',
    fontSize: '0.7rem',
  },
  tableCell: {
    textAlign: 'left',
    padding: '5px 5px',
    borderBottom: 'none',
  },
  descriptionField: {
    marginTop: theme.spacing(0.5),
  },
}));

const SectionHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.tableHeader}>
      <Typography variant="h2" color="primary" className={classes.tabletitle}>
        {intl.formatMessage(messages.section)}
      </Typography>
    </div>
  );
};

export const SmallSectionList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [sections, setSections] = useState([]);

  const { keycloak } = useKeycloak();
  const { sectionId } = useParams();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  const getSection = useCallback(() => {
    const fetchSectionMethod = hasOrganizationAdminRole
      ? fetchSection
      : getSectionForManager;

    fetchSectionMethod(sectionId).then((response) => {
      setSections([response.data]);
    });
  }, [sectionId, hasOrganizationAdminRole]);

  useEffect(() => {
    getSection();
  }, [getSection]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEditOrgClick = () => {
    setDialogOpen(true);
  };

  const handleCloseEditOrgDialog = () => {
    setDialogOpen(false);
    getSection(sectionId);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={classes.tableHeader}>
        <SectionHeader />
      </div>
      <TableContainer>
        <Table className={classes.sectiontable}>
          <TableBody>
            {sections.map((section, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  {section.organizationName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {section.sectionName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {section.serviceCode}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {section.costCentre}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {section.title}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {section.externalType}
                </TableCell>
                {hasOrganizationAdminRole && (
                  <TableCell className={classes.tableCell}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleEditOrgClick}
                      className={classes.editButton}
                    >
                      {intl.formatMessage(messages.general_edit)}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {sections[0] && (
        <div className={classes.descriptionField}>
          <DescriptionField
            section={sections[0]}
            hasOrganizationAdminRole={hasOrganizationAdminRole}
          />
        </div>
      )}

      <OrgAdminActionDialog
        dialogType="section"
        open={dialogOpen}
        handleClose={handleCloseEditOrgDialog}
        section={sections[0]}
        isEditing={true}
        updateListCallback={getSection}
      />
    </ErrorBoundary>
  );
};
