import React from 'react';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from 'messages.js';

export const FormDatePicker = (props) => {
  const intl = useIntl();
  const { newExpirationDate, handleOnChange, showError, dateNow, maxDate } =
    props;

  return (
    <TextField
      id="date"
      InputProps={{ inputProps: { min: dateNow, max: maxDate } }}
      label={intl.formatMessage(messages.expiration_date)}
      type="date"
      onChange={handleOnChange}
      value={newExpirationDate}
      sx={{ width: 220 }}
      error={showError}
      helperText={showError && intl.formatMessage(messages.date_picker_error)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
