import Keycloak from 'keycloak-js';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

let keycloak;

export default () =>
  (keycloak ??= new Keycloak({
    clientId: runtimeConfig.keycloakClientId,
    realm: runtimeConfig.keycloakRealm,
    url: runtimeConfig.keycloakUrl,
  }));
