import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useForm } from '@visma/formula';

import { ServiceContext } from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';

import { fetchPin, verifyPinForForm } from 'api';
import { SnackbarVariant, Tags } from '../../constants';
import messages from 'messages';

import { FormContent } from './FormContent';
import runtimeConfig from '@super-template/core/src/runtimeConfig/index.js';
import { KEIKKALAISTUNNUSTILAUS } from './FormTypes.js';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '2rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  formRoot: {
    display: 'flex',
    flexFlow: 'column',
    '& > *': {
      margin: 'auto',
      marginBottom: '1rem',
      width: '25ch',
    },
  },
}));

export const FormView = () => {
  const [locked, setLocked] = useState(true);
  const [serviceId, setServiceId] = useState('');
  const [showPinError, setShowServiceIdError] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { id } = useParams();
  const form = useForm(id);

  const serviceContext = useContext(ServiceContext);
  const { setOrganizationData } = serviceContext;

  const unlock = (e) => {
    e.preventDefault();
    if (serviceId === '') {
      setShowServiceIdError(true);
      return;
    } else {
      setShowServiceIdError(false);
    }

    // Determine unlock type: service code (multiple options) or password (only one option)
    // Password required forms: keikkalaistunnus form
    if (id === runtimeConfig.keikkalaistunnusForm.id.toString()) {
      verifyPinForForm(serviceId, KEIKKALAISTUNNUSTILAUS)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar(intl.formatMessage(messages.password_accepted), {
              variant: SnackbarVariant.SUCCESS,
              autoHideDuration: 2000,
            });
            setLocked(false);
          } else if (res.status === 204) {
            enqueueSnackbar(intl.formatMessage(messages.password_not_found), {
              variant: SnackbarVariant.WARNING,
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(intl.formatMessage(messages.general_error), {
              variant: SnackbarVariant.ERROR,
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.message, { variant: SnackbarVariant.ERROR })
        );
    } else {
      fetchPin(serviceId)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar(intl.formatMessage(messages.serviceId_accepted), {
              variant: SnackbarVariant.SUCCESS,
              autoHideDuration: 2000,
            });
            setOrganizationData(res.data);
            setLocked(false);
          } else if (res.status === 204) {
            enqueueSnackbar(intl.formatMessage(messages.serviceId_not_found), {
              variant: SnackbarVariant.WARNING,
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(intl.formatMessage(messages.general_error), {
              variant: SnackbarVariant.ERROR,
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.message, { variant: SnackbarVariant.ERROR })
        );
    }
  };

  const isLocked = () => {
    return locked && form.tags.includes(Tags.LOCKED);
  };

  const getLockedFormText = () => {
    if (id === runtimeConfig.keikkalaistunnusForm.id.toString()) {
      return intl.formatMessage(messages.form_locked_by_password);
    } else {
      return intl.formatMessage(messages.form_locked_by_serviceCode);
    }
  };

  const getLockedFormInputLabel = () => {
    if (id === runtimeConfig.keikkalaistunnusForm.id.toString()) {
      return 'Salasana';
    } else {
      return 'Palvelukoodi';
    }
  };

  const handleOnChange = (e) => {
    if (e.target.value === '' && showPinError) {
      setShowServiceIdError(true);
    } else {
      setShowServiceIdError(false);
    }
    setServiceId(e.target.value);
  };

  return (
    <>
      {isLocked() ? (
        <>
          <Typography className={classes.title} variant="h6">
            {getLockedFormText()}
          </Typography>
          <form className={classes.formRoot} noValidate autoComplete="off">
            <TextField
              error={showPinError}
              id="serviceId-koodi"
              label={getLockedFormInputLabel()}
              autoFocus={true}
              value={serviceId}
              onChange={handleOnChange}
              helperText={
                showPinError &&
                intl.formatMessage(messages.serviceId_input_empty)
              }
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={unlock}
            >
              {intl.formatMessage(messages.open)}
            </Button>
          </form>
        </>
      ) : (
        <>
          <FormContent form={form} id={id} />
        </>
      )}
    </>
  );
};
