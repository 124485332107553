import React, { useState } from 'react';
import { Typography, Grid, RadioGroup } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useLogout } from '@super-template/axios-keycloak-react-auth-integration/src/api';
import { useSnackbar } from 'notistack';

import RegistrationNav from './WizardControls';
import { TermCheckbox } from './TermCheckbox';
import messages from 'messages';

const ServiceAgreement = ({ classes, handleNextStep, handlePreviousStep }) => {
  const [state, setState] = useState({
    acceptServiceAgreement: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const logout = useLogout();
  const intl = useIntl();

  const handleChange = (ev) => {
    setState({ acceptServiceAgreement: ev.target.value });
  };

  const handleNext = () => {
    if (state.acceptServiceAgreement === 'decline') {
      enqueueSnackbar(intl.formatMessage(messages.registration_interrupted), {
        variant: 'info',
      });
      return logout();
    } else if (state.acceptServiceAgreement === 'accept') {
      handleNextStep();
    } else {
      enqueueSnackbar(intl.formatMessage(messages.select_one), {
        variant: 'info',
      });
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className={classes.borderContainer}
      >
        <Grid item xs={4} className={classes.border}></Grid>
        <Grid item xs={4} className={classes.borderActive}></Grid>
      </Grid>
      <Grid item md={5} sm={11} xs={11}>
        <Typography variant="subtitle2" className={classes.paragraph}>
          {intl.formatMessage(messages.registration_step_2)}
        </Typography>
        <Typography variant="body2" className={classes.parahraph}>
          {intl.formatMessage(messages.registration_step_2_body)}
        </Typography>
      </Grid>
      <Grid item md={5} sm={11} xs={11}>
        <RadioGroup
          onChange={handleChange}
          value={state.acceptServiceAgreement}
          name="serviceAgreementRadioGroup"
        >
          <TermCheckbox
            label={intl.formatMessage(messages.registration_concent_accept)}
            name="acceptServiceAgreement"
            value="accept"
          />
          <TermCheckbox
            label={intl.formatMessage(messages.registration_concent_decline)}
            name="declineServiceAgreement"
            value="decline"
          />
        </RadioGroup>
      </Grid>
      <RegistrationNav
        handleNext={handleNext}
        handlePrevious={handlePreviousStep}
        previousText={intl.formatMessage(messages.interrupt)}
        nextText={intl.formatMessage(messages.continue_to_app)}
      />
    </Grid>
  );
};

export default ServiceAgreement;
