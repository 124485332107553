import { useMemo } from 'react';
import useLocalStorageJson from '@postinumero/storage/useLocalStorageJson';
import defaultLocale from './default';

export default function useLocale() {
  const [locale, setLocale] = useLocalStorageJson('locale');
  const localeValue = locale ?? defaultLocale;

  return useMemo(() => [localeValue, setLocale], [localeValue, setLocale]);
}
