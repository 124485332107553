import { Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useLogout } from '@super-template/axios-keycloak-react-auth-integration/src/api';

import messages from 'messages';
import { setUserStatus, STATUS_MANAGER } from '../../userUtils.js';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '1rem',
    background: theme.palette.primary.hallintoBlueLight,
  },
  cardAction: {
    background: theme.palette.hallinto.orangeLight,
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.hallinto.orangeBorder}`,
    '&:hover': {
      backgroundColor: theme.palette.hallinto.orangeDark,
      borderColor: theme.palette.hallinto.orange,
    },
    '&:active': {
      borderColor: '8px black',
    },
  },
  cardText: {
    textAlign: 'center',
  },
  paragraph: {
    margin: '0.5rem 0',
  },
}));

export const ConcentForm = ({ handleClose }) => {
  const intl = useIntl();
  const classes = useStyles();

  const logout = useLogout();

  const handleAccept = () => {
    setUserStatus(STATUS_MANAGER);
    handleClose();
  };

  const handleDecline = () => {
    logout();
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <Grid md={5} sm={11} xs={11}>
          <Typography variant="subtitle1" className={classes.paragraph}>
            {intl.formatMessage(messages.manager_concent)}
          </Typography>
        </Grid>
        <Grid>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.cardAction}
              onClick={handleAccept}
            >
              <Typography variant="h6" className={classes.cardText}>
                {intl.formatMessage(messages.manager_accept)}
              </Typography>
            </CardActionArea>
          </Card>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.cardAction}
              onClick={handleDecline}
            >
              <Typography variant="h6" className={classes.cardText}>
                {intl.formatMessage(messages.manager_decline)}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
