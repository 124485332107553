export const ResponseStatus = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const SnackbarVariant = {
  SUCCESS: 'success',
  DEFAULT: 'default',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
};

export const Tags = {
  LOCKED: 'locked',
};

export const externalOptions = [
  '1 Ulkoinen käyttäjä',
  '2 Kumppani',
  '3 Keikkalainen',
  '4 Opiskelija-AMK',
  '5 Opiskelija-Yliopisto',
];
