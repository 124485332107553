const STATUS_KEY = 'imp_status';
export const STATUS_MANAGER = 'manager';
export const STATUS_APPLICANT = 'applicant';

const getImpersonationStatus = () => {
  const status = sessionStorage.getItem(STATUS_KEY);
  if (!status) return null;
  return status;
};

export const setUserStatus = (status) => {
  sessionStorage.setItem(STATUS_KEY, window.btoa(status));
};

export const getUserStatus = () => {
  return getImpersonationStatus()
    ? window.atob(getImpersonationStatus())
    : null;
};

export const removeUserStatus = () => {
  sessionStorage.removeItem(STATUS_KEY);
};
