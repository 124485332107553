import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { SnackbarVariant } from '../../../constants';
import messages from 'messages';
import { createManager, updateManager } from 'api';

const DEFAULT_MANAGER = {
  employeeNumber: '',
  socialSecurityNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  sectionIds: [],
};

export default function ManagerForm({
  manager,
  isEditing,
  handleClose,
  updateListCallback,
}) {
  const currentManager = manager || DEFAULT_MANAGER;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [formError, setFormError] = useState({
    socialSecurityNumber: false,
    email: false,
  });
  const { sectionId } = useParams();

  const [formState, setFormState] = useState({
    id: currentManager.id,
    firstName: currentManager.firstName,
    lastName: currentManager.lastName,
    email: currentManager.email,
    socialSecurityNumber: currentManager.socialSecurityNumber,
    employeeNumber: currentManager.employeeNumber,
    sectionIds: [sectionId],
  });

  useEffect(() => {
    if (
      formState.firstName &&
      formState.lastName &&
      formState.email &&
      (formState.socialSecurityNumber || formState.employeeNumber) &&
      !formError.socialSecurityNumber &&
      !formError.email
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [formState, formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });

    switch (name) {
      case 'socialSecurityNumber':
        setFormState({ ...formState, [name]: value.toUpperCase() });

        setFormError({
          ...formError,
          [name]:
            value !== '' &&
            !/^[0-9]{6}[Aa-][0-9]{3}[A-FHJ-NPR-TU-Ya-fhj-npr-tu-y0-9]$/.test(
              value
            ),
        });
        break;

      case 'email':
        setFormError({
          ...formError,
          [name]: !/.+@.+\.[A-Za-z]+$/.test(value),
        });
        break;

      default:
        break;
    }
  };

  const editManager = () => {
    if (!disableSubmit) {
      const trimmedFormState = {
        ...formState,
        employeeNumber: formState.employeeNumber
          ? formState.employeeNumber.trim()
          : formState.employeeNumber,
        socialSecurityNumber: formState.socialSecurityNumber
          ? formState.socialSecurityNumber.trim()
          : formState.socialSecurityNumber,
        email: formState.email.trim(),
      };
      updateManager(trimmedFormState, formState.id)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_error), {
            variant: SnackbarVariant.ERROR,
          });
        });
    }
  };

  const createNewManager = () => {
    if (!disableSubmit) {
      const trimmedFormState = {
        ...formState,
        employeeNumber: formState.employeeNumber
          ? formState.employeeNumber.trim()
          : formState.employeeNumber,
        socialSecurityNumber: formState.socialSecurityNumber
          ? formState.socialSecurityNumber.trim()
          : formState.socialSecurityNumber,
        email: formState.email.trim(),
      };
      createManager(trimmedFormState)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.general_save_success), {
            variant: SnackbarVariant.SUCCESS,
          });
          updateListCallback();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              enqueueSnackbar(intl.formatMessage(messages.manager_duplicate), {
                variant: SnackbarVariant.ERROR,
              });
            } else {
              enqueueSnackbar(intl.formatMessage(messages.general_error), {
                variant: SnackbarVariant.ERROR,
              });
            }
          }
        });
    }
  };

  const dialogContentText = isEditing
    ? messages.manager_edit
    : messages.manager_create;

  const actionButtonOnClick = isEditing ? editManager : createNewManager;

  return (
    <>
      <DialogTitle id="confirm-dialog-title">
        <Typography component="h3">
          {intl.formatMessage(messages.manager)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <Typography component="subtitle2">
            {intl.formatMessage(dialogContentText)}
          </Typography>
        </DialogContentText>
        <form>
          <TextField
            variant="standard"
            required={!formState.socialSecurityNumber}
            name="employeeNumber"
            label="Henkilönumero"
            fullWidth
            value={formState.employeeNumber}
            onChange={handleChange}
            error={formError.employeeNumber && !formState.socialSecurityNumber}
            disabled={formState.socialSecurityNumber}
          />
          <TextField
            variant="standard"
            required={!formState.employeeNumber}
            name="socialSecurityNumber"
            label="Henkilötunnus"
            fullWidth
            value={formState.socialSecurityNumber}
            onChange={handleChange}
            inputProps={{ maxLength: 11 }}
            error={formError.socialSecurityNumber && !formState.employeeNumber}
            helperText={
              formError.socialSecurityNumber ? 'Tarkista henkilötunnus' : ''
            }
            disabled={formState.employeeNumber}
          />
          <TextField
            variant="standard"
            required
            name="firstName"
            label="Etunimi"
            type="firstName"
            fullWidth
            value={formState.firstName}
            error={!formState.firstName && !formState.firstName === ''}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            required
            name="lastName"
            label="Sukunimi"
            fullWidth
            value={formState.lastName}
            error={!formState.lastName && !formState.lastName === ''}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            required
            name="email"
            label="Sähköposti"
            fullWidth
            value={formState.email}
            onChange={handleChange}
            error={formError.email}
            helperText={formError.email ? 'Tarkista sähköposti' : ''}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {intl.formatMessage(messages.back)}
        </Button>
        <Button
          disabled={disableSubmit}
          variant="contained"
          size="small"
          color="primary"
          onClick={actionButtonOnClick}
        >
          {intl.formatMessage(messages.send)}
        </Button>
      </DialogActions>
    </>
  );
}
