import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';

import { fetchOrganization, getOrganizationForManager } from 'api';
import messages from 'messages.js';
import OrgAdminActionDialog from 'components/OrgAdminActionDialog/OrgAdminActionDialog';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listHeader: {
    height: '30px',
    background: theme.palette.common.greyLight,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  listTitle: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  orgName: {
    fontSize: '0.8rem',
  },
  editButton: {
    marginLeft: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    padding: '3px 5px',
    fontSize: '0.7rem',
  },
}));

const OrganizationHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.listHeader}>
      <Typography variant="h2" color="primary" className={classes.listTitle}>
        {intl.formatMessage(messages.organization)}
      </Typography>
    </div>
  );
};

export const SmallOrgList = ({ orgId, hasOrganizationAdminRole }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [organizations, setOrganizations] = useState([]);

  const fetchOrg = useCallback(
    (id) => {
      const fetchOrgMethod = hasOrganizationAdminRole
        ? fetchOrganization
        : getOrganizationForManager;

      fetchOrgMethod(id).then((response) => {
        setOrganizations([response.data]);
      });
    },
    [hasOrganizationAdminRole]
  );

  useEffect(() => {
    fetchOrg(orgId);
  }, [orgId, fetchOrg]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEditOrgClick = () => {
    setDialogOpen(true);
  };

  const handleCloseEditOrgDialog = () => {
    setDialogOpen(false);
    fetchOrg(orgId);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OrganizationHeader />
      <List className={classes.listRoot}>
        {organizations.map((organization, index) => (
          <div key={index}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" className={classes.orgName}>
                    {/* Decreased typography variant */}
                    {organization.organizationName}
                  </Typography>
                }
              />
              {hasOrganizationAdminRole && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleEditOrgClick}
                  className={classes.editButton}
                >
                  {intl.formatMessage(messages.general_edit)}
                </Button>
              )}
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
      <OrgAdminActionDialog
        dialogType="organization"
        open={dialogOpen}
        handleClose={handleCloseEditOrgDialog}
        organization={organizations[0]}
        isEditing={true}
        updateListCallback={fetchOrganization}
      />
    </ErrorBoundary>
  );
};
