import React, { useContext } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Button,
} from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import { Typography, TableHead } from '@material-ui/core';
import { FormattedDate, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import commonMessages from 'messages';
import { MessageContext } from '../../contexts/MessageProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.hallintoBlue,
    color: theme.palette.white,
    padding: theme.spacing(1),
    display: 'flex',
  },
  title: {
    paddingLeft: theme.spacing(2),
  },
  tableContainer: {
    paddingTop: '8px',
  },
  table: {
    textAlign: 'left',
    tableHead: {},
  },
  row: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
}));

const TableHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <Email />
      <Typography component="h1" className={classes.title}>
        {intl.formatMessage(commonMessages.inbox)}
      </Typography>
    </div>
  );
};

export const Inbox = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { messages } = useContext(MessageContext);

  return (
    <>
      <TableHeader />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.row}>
              <TableCell align="left">
                {intl.formatMessage(commonMessages.subject)}
              </TableCell>
              <TableCell align="left">
                {intl.formatMessage(commonMessages.body)}
              </TableCell>
              <TableCell align="left">
                {intl.formatMessage(commonMessages.date)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.length !== 0 ? (
              <>
                {messages.map((message) => (
                  <TableRow
                    key={message.id}
                    className={classes.row}
                    {...(message.isRead && {
                      style: { backgroundColor: 'rgba(211, 211, 211, 0.3)' },
                    })}
                  >
                    <TableCell align="left">{message.messageSubject}</TableCell>
                    <TableCell align="left">
                      {message.message.length > 30
                        ? message.message.substring(0, 30) + '...'
                        : message.message}
                    </TableCell>
                    <TableCell align="left">
                      <FormattedDate value={message.date} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          history.push(`message/${message.id}`);
                        }}
                      >
                        {intl.formatMessage(commonMessages.open)}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow className={classes.row}>
                <TableCell align="left">{'Ei viestejä'}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
