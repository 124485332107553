import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';

import UserMenu from './UserMenu';
import { path as home } from 'components/Routes/Home';
import messages from 'messages';

const useStyles = makeStyles((theme) => ({
  navbarRoot: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.hallinto.blueDark,
    width: '100%',
    height: '13vh',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 20),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '7vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '5vh',
    },
  },
  title: {
    margin: '0 auto',
    color: theme.palette.white,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    maxWidth: '90rem',
    margin: '0 auto',
  },
  menu: {
    margin: 'auto',
  },
  login: {
    float: 'right',
    color: theme.palette.white,
    textDecoration: 'none',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },
  logo: {
    height: '2rem',
  },
}));

export default function NavBar({ title }) {
  const classes = useStyles();
  const intl = useIntl();
  const { keycloak } = useKeycloak();

  return (
    <AppBar position="static">
      <Toolbar className={classes.navbarRoot}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={2} className={classes.title}>
            <Link to={home} style={{ textDecoration: 'none' }}>
              <Typography
                style={{ color: 'white' }}
                variant="h3"
                data-type={'title'}
              >
                {intl.formatMessage(messages.pirha)}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={8} className={classes.menu}>
            {keycloak.authenticated && <UserMenu title={title} />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
