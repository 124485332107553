import React from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { makeStyles } from '@material-ui/core/styles';
import { ResponseStatus } from '../../../constants';
import messages from 'messages';

import { ExportMenu } from 'components/ExportMenu';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  listItem: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
  listIcon: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      margin: '0 5px',
    },
  },
  editButton: {
    padding: '4px 15px',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  editButtonIcon: {
    display: 'none',
    paddingRight: '.5rem',
    transform: 'translateY(2px)',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  exportButton: {
    padding: '4px 15px',
    marginRight: '1rem',
  },
}));

export const FormRow = ({ form, checkable, onCheckChange }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon className={classes.listIcon}>
        {form.responseStatus === ResponseStatus.ACCEPTED ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : form.responseStatus === ResponseStatus.REJECTED ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          // status == Other
          <AddCircleIcon color="primary" />
        )}
      </ListItemIcon>
      <ListItemText>{form.title}</ListItemText>
      {form.credentialsStartDate && (
        <ListItemText>
          <FormattedDate value={form.credentialsStartDate} />
        </ListItemText>
      )}

      <ExportMenu title={form.title} id={form.formulaResponseId} />
      <Button
        variant="contained"
        color="primary"
        size="small"
        href={`edit/${form.formId}/${form.id}/${form.formulaResponseId}`}
        className={classes.editButton}
      >
        {intl.formatMessage(messages.general_edit)}
      </Button>

      <a
        href={`edit/${form.formId}/${form.id}/${form.formulaResponseId}`}
        className={classes.editButtonIcon}
      >
        <EditRoundedIcon color="primary" fontSize="small" />
      </a>
      {checkable && (
        <ListItemSecondaryAction>
          <Checkbox
            value={form.id}
            onChange={onCheckChange}
            checked={form.checked}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
