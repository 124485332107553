import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Pagination } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import cuid from 'cuid';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';

import { useKeycloak } from '@react-keycloak/web';
import {
  Button,
  ButtonGroup,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';

import messages from 'messages.js';
import { deleteSection, fetchSectionList, getSectionsForManager } from 'api';
import DeleteButton from '../DeleteButton';
import Search from '../Search';
import OrgAdminActionDialog from 'components/OrgAdminActionDialog/OrgAdminActionDialog';
import { SmallOrgList } from './SmallOrgList';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    height: '50px',
    background: theme.palette.common.greyLight,
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  tabletitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: '1',
    verticalAlign: 'middle',
  },
  sectiontable: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  tableRow: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
  tableCell: {
    textAlign: 'left',
    padding: '10px 10px',
  },
  tableButton: {
    color: theme.palette.hallinto.blueDark,
  },

  actionFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const SectionList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const { orgId } = useParams();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');
  const [sectionList, setSectionList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    getSectionsList(orgId);
    // eslint-disable-next-line
  }, [location]);

  const getSectionsList = () => {
    const fetchSectionsMethod = hasOrganizationAdminRole
      ? fetchSectionList
      : getSectionsForManager;

    fetchSectionsMethod(orgId).then((response) => {
      setSectionList(response.data);
    });
  };

  const handleEditClick = (section) => {
    setSelectedSection(section);
    setDialogType('section');
    setDialogOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedSection(null);
    setDialogType('section');
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = (id) => {
    const indexInSectionList = sectionList.findIndex((item) => item.id === id);

    if (indexInSectionList > -1) {
      deleteSection(sectionList[indexInSectionList].id)
        .then(() => {
          const newSectionList = [...sectionList];
          newSectionList.splice(indexInSectionList, 1);
          setSectionList(newSectionList);
        })
        .catch(() => {});
    }
  };

  const handleBackButtonClick = () => {
    history.push('/listview');
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Search />
      <div className={classes.smallList}>
        <SmallOrgList
          orgId={orgId}
          hasOrganizationAdminRole={hasOrganizationAdminRole}
        />
      </div>
      <div className={classes.sectiontable}>
        <div className={classes.searchContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.backButton}
            onClick={() => handleBackButtonClick()}
          >
            {intl.formatMessage(messages.back)}
          </Button>
        </div>
        <div className={classes.tableHeader}>
          <Typography
            variant="h2"
            color="primary"
            className={classes.tabletitle}
          >
            <ListAltIcon className={classes.icon} />
            {intl.formatMessage(messages.section_title)}
          </Typography>
        </div>
        <TableContainer>
          <Table className={classes.sectiontable}>
            {sectionList
              .slice((page - 1) * PER_PAGE, page * PER_PAGE)
              .map((section) => (
                <TableRow
                  className={classes.tableRow}
                  key={cuid()}
                  onClick={(event) => {
                    if (
                      event.target.tagName !== 'BUTTON' &&
                      event.target.parentElement.tagName !== 'BUTTON'
                    ) {
                      history.push(`/listview/${orgId}/${section.id}`);
                    }
                  }}
                >
                  <TableCell className={classes.tableCell}>
                    {section.organizationName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {section.sectionName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {section.serviceCode}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {section.costCentre}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {section.title}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {section.externalType}
                  </TableCell>
                  {hasOrganizationAdminRole && (
                    <TableCell className={classes.tableCell}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleEditClick(section);
                        }}
                      >
                        {intl.formatMessage(messages.general_edit)}
                      </Button>
                      <DeleteButton
                        id={section.id}
                        handleDeleteCallback={handleDelete}
                        className={classes.tableButton}
                      >
                        {intl.formatMessage(messages.general_delete)}
                      </DeleteButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </Table>
        </TableContainer>
        <div className={classes.actionFooter}>
          <Pagination
            count={Math.ceil(sectionList.length / PER_PAGE)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
          <ButtonGroup>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setDialogType('csv');
                setDialogOpen(true);
              }}
            >
              {intl.formatMessage(messages.export_external_csv)}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCreateClick}
            >
              {intl.formatMessage(messages.add)}
            </Button>
          </ButtonGroup>
        </div>
        <OrgAdminActionDialog
          dialogType={dialogType}
          uploadType={'section'}
          open={dialogOpen}
          orgId={orgId}
          handleClose={handleCloseDialog}
          section={selectedSection}
          isEditing={selectedSection !== null}
          updateListCallback={() => getSectionsList(orgId)}
        />
      </div>
    </ErrorBoundary>
  );
};
