import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
} from '@material-ui/core';
import cuid from 'cuid';
import { useForms } from '@visma/formula';
import { makeStyles } from '@material-ui/core/styles';

import { useIntl } from 'react-intl';
import messages from 'messages';
import { getUserStatus } from 'userUtils';
import runtimeConfig from '@super-template/core/src/runtimeConfig/index.js';

const useStyles = makeStyles((theme) => ({
  formListRoot: {
    background: theme.palette.primary.hallintoBlue,
    color: theme.palette.white,
    display: 'flex',
  },
  listItem: {
    border: '1px solid rgba(0,0,0,0.2)',
  },
}));

const ListHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.formListRoot}>
      <Typography variant="h4" color="primary" className={classes.title}>
        {intl.formatMessage(messages.form_inquiries)}
      </Typography>
    </div>
  );
};

export const FormList = () => {
  const classes = useStyles();
  const intl = useIntl();
  let forms = useForms({ status: 'published' });

  if (getUserStatus() !== 'applicant') {
    forms = forms.filter((form) => {
      return (
        form._id.toString() !== runtimeConfig.applicationForm.id.toString()
      );
    });
  }

  return (
    <>
      <ListHeader />
      <List>
        {forms.map((form) => (
          <ListItem className={classes.listItem} key={cuid()}>
            <ListItemText>{form.title}</ListItemText>
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                size="small"
                href={`form/${form._id}`}
              >
                {intl.formatMessage(messages.fill)}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};
