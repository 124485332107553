import React, { useEffect, useContext } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { messageRead } from 'api';
import { MessageContext } from '../../contexts/MessageProvider';
import commonMessages from 'messages';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    padding: '1rem',
  },
  messageSubject: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    margin: '0.5rem 0',
  },
  hr: {
    border: '0',
    borderBottom: '1px solid gray',
    margin: '1rem 0',
  },
  dateTimeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  messageDate: {
    fontSize: '1rem',
    margin: '0.5rem 0',
  },
  messageTime: {
    fontSize: '1rem',
    marginLeft: '1rem',
    marginTop: '0.5rem',
  },
  messageBody: {
    whiteSpace: 'pre-line',
    margin: '0.5rem 0',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    padding: '0.5rem',
    float: 'right',
  },
}));

export const MessageView = () => {
  const intl = useIntl();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { messages } = useContext(MessageContext);

  useEffect(() => {
    messageRead(id);
  }, [id]);

  const Message = () => {
    const message = messages.find((message) => message.id === parseInt(id));
    if (!message)
      return (
        <Typography style={{ position: 'center' }} variant="h6">
          Viestiä ei löydy
        </Typography>
      );

    return (
      <>
        <div className={classes.root}>
          <div className={classes.headerContainer}>
            <h3 className={classes.messageSubject}>{message.messageSubject}</h3>
            <div className={classes.dateTimeContainer}>
              <div className={classes.messageDate}>
                <FormattedDate value={message.date} />
              </div>
              <div className={classes.messageTime}>
                <FormattedTime value={message.date} />
              </div>
            </div>
          </div>
          <hr className={classes.hr} />
          <div className={classes.messageBody}>{message.message}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <Message />
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.backButton}
        onClick={() => {
          history.push('/inbox');
        }}
      >
        {intl.formatMessage(commonMessages.back)}
      </Button>
    </>
  );
};
