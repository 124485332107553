import { Dialog } from '@material-ui/core';
import CsvImport from 'components/CsvImport/CsvImport';
import SectionsForm from './Forms/SectionsForm';
import OrganizationForm from './Forms/OrganizationForm';
import ManagerForm from './Forms/ManagerForm';
import ApplicantForm from './Forms/ApplicantForm';

export default function OrgAdminActionDialog({
  open,
  handleClose,
  organization,
  updateListCallback,
  isEditing,
  dialogType,
  section,
  manager,
  applicant,
  orgId,
  sectionId,
  uploadType,
}) {
  const renderDialogContent = () => {
    switch (dialogType) {
      case 'organization':
        return (
          <OrganizationForm
            organization={organization}
            isEditing={isEditing}
            handleClose={handleClose}
            updateListCallback={updateListCallback}
          />
        );
      case 'section':
        return (
          <SectionsForm
            section={section}
            isEditing={isEditing}
            orgId={orgId}
            handleClose={handleClose}
            updateListCallback={updateListCallback}
          />
        );
      case 'manager':
        return (
          <ManagerForm
            manager={manager}
            isEditing={isEditing}
            handleClose={handleClose}
            updateListCallback={updateListCallback}
            sectionId={sectionId}
          />
        );
      case 'applicant':
        return (
          <ApplicantForm
            applicant={applicant}
            isEditing={isEditing}
            handleClose={handleClose}
            updateListCallback={updateListCallback}
            sectionId={sectionId}
          />
        );
      case 'csv':
        return (
          <CsvImport
            handleClose={handleClose}
            uploadType={uploadType}
            updateListCallback={updateListCallback}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {renderDialogContent()}
    </Dialog>
  );
}
