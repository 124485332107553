import React from 'react';
import { useIntl } from 'react-intl';
import PageWithDrawer from 'components/layouts/PageWithDrawer';
import messages from 'messages';
import { RenewalSuccessView } from 'components/AccountRenewal/RenewalSuccess';

export const path = '/renewalform/success';

export const component = function RenewalSuccess() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.forms)}>
      <RenewalSuccessView />
    </PageWithDrawer>
  );
};
