import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import messages from 'messages';

import logo from 'images/PS_frontpage_web.jpg';
import { getUserStatus } from 'userUtils';

const useStyles = makeStyles(() => ({
  title: {
    paddingLeft: '0.5rem',
  },
  hero: {
    width: '100%',
  },
}));

export default function Private() {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const userStatus = getUserStatus();
  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  if (hasOrganizationAdminRole || userStatus === 'manager') {
    history.push('/listview');
  }

  return (
    <>
      <h1 className={classes.title}>
        <Typography color="primary" variant="h4">
          {intl.formatMessage(messages.home)}
        </Typography>
      </h1>
      <img src={logo} alt="" className={classes.hero} />
    </>
  );
}
