import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { useUserClaim } from '@super-template/axios-keycloak-react-auth-integration/src/useUserClaim';

import { createUser } from 'api';
import messages from 'messages';

const defaultFn = () => {
  throw Error('ContextFn has not been initialised correctly');
};

const defaultState = {
  isAdmin: false,
  isActive: true,
  OrganizationData: {},
  setConcentStatus: defaultFn,
  clearState: defaultFn,
  setOrganizationData: defaultFn,
  setAdminStatus: defaultFn,
};

export const ServiceContext = React.createContext(defaultState);
export const ServiceConsumer = ServiceContext.Consumer;

const ServiceProvider = ({ fallback, children, registration }) => {
  const [state, setState] = React.useState(defaultState);
  const serviceAgreement = useUserClaim('e_service_agreement');
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    (async () => {
      if (keycloak.authenticated) {
        const hasAcceptedServiceAgreement = serviceAgreement;
        const hasAdminRole = keycloak.hasResourceRole('admin');

        // Check if admin. Don't show registration to admin.
        if (hasAdminRole) {
          setAdminStatus(true);

          try {
            await createUser();
          } catch (err) {
            switch (err.response.status) {
              case 409:
                // User already exists. No action required.
                break;
              case 401:
                enqueueSnackbar(intl.formatMessage(messages.session_expired), {
                  variant: 'error',
                });
                break;
              default:
                enqueueSnackbar(
                  intl.formatMessage(messages.user_creation_error),
                  { variant: 'error' }
                );
            }
          }
        } else {
          // If not admin and serviceAgreement hasn't been accepted, show registration
          if (!hasAcceptedServiceAgreement) {
            setConcentStatus(false);
          }
        }
      }
    })();
  }, [serviceAgreement, intl, enqueueSnackbar, keycloak]);

  const setConcentStatus = (status) => {
    setState((prev) => {
      return { ...prev, isActive: status };
    });
  };
  const setOrganizationData = (data) => {
    setState((prev) => {
      return { ...prev, OrganizationData: { ...data } };
    });
  };
  const setAdminStatus = (status) => {
    setState((prev) => {
      return { ...prev, isAdmin: status };
    });
  };

  const clearState = () => {
    setState(() => {
      return initState;
    });
  };

  const initState = {
    ...state,
    setConcentStatus,
    setOrganizationData,
    setAdminStatus,
    clearState,
  };

  return (
    <ServiceContext.Provider value={initState}>
      {keycloak.authenticated
        ? state.isActive || state.isAdmin
          ? children
          : registration
        : fallback}
    </ServiceContext.Provider>
  );
};

export default ServiceProvider;
