import { defineMessages } from 'react-intl';

// App specific common messages
export default defineMessages({
  // General
  pirha: { defaultMessage: 'Pirkanmaan hyvinvointialue' },
  login: { defaultMessage: 'Kirjaudu sisään' },
  loginAdmin: { defaultMessage: 'Kirjaudu ylläpitäjänä' },
  loginManager: { defaultMessage: 'Kirjaudu managerina' },
  loginApplicant: { defaultMessage: 'Kirjaudu hakijana' },
  logout: { defaultMessage: 'Kirjaudu ulos' },
  admin: { defaultMessage: 'Hallinta' },
  back: { defaultMessage: 'Takaisin' },
  home: { defaultMessage: 'Etusivu' },
  forms: { defaultMessage: 'Lomakkeet' },
  first_name: { defaultMessage: 'Etunimi' },
  family_name: { defaultMessage: 'Sukunimi' },
  terms: { defaultMessage: 'Käyttöehdot' },
  new: { defaultMessage: 'Uusi' },
  accept: { defaultMessage: 'Hyväksy' },
  reject: { defaultMessage: 'Hylkää' },
  export: { defaultMessage: 'Vie...' },
  export_csv: { defaultMessage: 'Vie CSV' },
  import_csv: { defaultMessage: 'Tuo CSV:llä' },
  export_external_csv: { defaultMessage: 'Lataa CSV-tiedosto' },
  csv_import_error_generic: { defaultMessage: 'Virhe CSV-tuonnissa' },
  csv_import_error_check_csv: {
    defaultMessage: 'Virhe CSV-tuonnissa: Tarkista CSV-tiedoston tiedot',
  },
  csv_import_error_exists: {
    defaultMessage:
      'Virhe CSV-tuonnissa: Tieto jota yrität lisätä on jo olemassa',
  },
  add: { defaultMessage: 'Lisää' },
  open: { defaultMessage: 'Avaa' },
  fill: { defaultMessage: 'Täytä' },
  send: { defaultMessage: 'Lähetä' },
  ext_expiration_date: { defaultMessage: 'Oikeuden eräpäivä:' },
  expiration_date: { defaultMessage: 'Päättymispäivä' },
  date_picker_error: { defaultMessage: 'Tarkista päivämäärä' },
  as_file: { defaultMessage: 'Tiedostona' },
  select_one: {
    defaultMessage: 'Valitse ainakin yksi seuraavista vaihtoehdoista',
  },
  select_all: { defaultMessage: 'Valitse kaikki' },
  select_none: { defaultMessage: 'Poista valinnat' },
  no_forms_found: { defaultMessage: 'Lomakkeita ei löytynyt' },
  sso: { defaultMessage: 'Henkilötunnus' },
  name: { defaultMessage: 'Nimi' },
  address: { defaultMessage: 'Osoite' },
  interrupt: { defaultMessage: 'Keskeytä' },
  next: { defaultMessage: 'Seuraava' },
  previous: { defaultMessage: 'Edellinen' },
  continue_to_app: { defaultMessage: 'Jatka palveluun' },
  session_expired: {
    defaultMessage: 'Istunto on vanhentunut, kirjaudu uudestaan',
  },
  general_error: {
    defaultMessage: 'Tuntematon virhe, yritä hetken kuluttua uudelleen',
  },
  general_save_success: {
    defaultMessage: 'Tallennus onnistui',
  },
  general_not_found: { defaultMessage: 'Tietoa ei löytynyt' },
  general_true: {
    defaultMessage: 'Kyllä',
  },
  general_false: {
    defaultMessage: 'Ei',
  },
  general_print: {
    defaultMessage: 'Tulosta',
  },
  general_edit: {
    defaultMessage: 'Muokkaa',
  },
  general_form: {
    defaultMessage: 'lomake',
  },
  general_delete: {
    defaultMessage: 'Poista',
  },
  general_save: {
    defaultMessage: 'Tallenna',
  },
  // Registration
  registration_accepted: { defaultMessage: 'Suostumus hyväksytty' },
  registration_title: {
    defaultMessage: 'Tunnistautuminen',
  },
  registration_step_1_title: {
    defaultMessage: 'Käyttöehdot',
  },
  registration_step_1: {
    defaultMessage: 'Palvelun käyttöönotto, vaihe 1/2',
  },
  registration_step_1_body: {
    defaultMessage:
      'Tutustu palvelun käyttöehtoihin ja anna suostumus tietojesi käsittelyyn.',
  },
  registration_step_1_action: {
    defaultMessage: 'Avaa palvelun käyttöehdot',
  },
  registration_step_1_short: {
    defaultMessage: 'Olen hyväksynyt käyttöehdot',
  },
  registration_step_2_title: {
    defaultMessage: 'Sähköisen asioinnin suostumus',
  },
  registration_step_2: {
    defaultMessage: 'Palvelun käyttöönotto, vaihe 2/2',
  },
  registration_step_2_body: {
    defaultMessage:
      'Saat palvelun käyttöösi antamalla sähköisen asioinnin suostumuksen. Suostumuksesi tallennetaan palveluun. Palvelun käyttöönoton jälkeen voit hallinnoida suostumusta omissa tiedoissasi.',
  },
  registration_step_2_short: {
    defaultMessage:
      'Olen hyväksynyt sähköisen asioinnin sidosryhmat.pirha.fi-palvelussa',
  },
  registration_concent: {
    defaultMessage:
      'Tunnistautumisen yhteydessä väestötietojärjestelmästä haetut tietosi välitetään palveluun.',
  },
  registration_concent_accept: {
    defaultMessage:
      'Annan suostumukseni sähköiseen asiointiin. Minulle voi lähettää tekstiviesti-ja sähköposti-ilmoituksia sekä viestejä palveluun.',
  },
  registration_concent_decline: {
    defaultMessage:
      'En anna suostumusta sähköiseen asiointiin. Valinta keskeyttää palvelun käyttöönoton.',
  },
  registration_terms_accept: {
    defaultMessage:
      'Hyväksyn palvelun käyttöehdot ja annan suostumukseni palveluun tallennettujen tietojeni käsittelyyn.',
  },
  registration_terms_decline: {
    defaultMessage:
      'En hyväksy käyttöehtoja, enkä salli tietojeni käsittelyä. Valinta keskeyttää palvelun käyttöönoton.',
  },
  registration_person_information: {
    defaultMessage:
      'Tutustu palvelun tietosuojaselosteeseen, jossa on kerrottu tietojen käyttötarkoituksesta.',
  },
  registration_read_terms: {
    defaultMessage: 'Avaa palvelun tietosuojaseloste',
  },
  registration_interrupted: {
    defaultMessage:
      'Keskeytetty käyttöönotto, ole hyvä ja kirjaudu uudestaan jos tämä oli virhe.',
  },
  user_creation_error: {
    defaultMessage:
      'Käyttäjän tallentamisessa tapahtui virhe, yritä hetken kuluttua uudelleen.',
  },
  // Form
  serviceId_input_empty: { defaultMessage: 'Palvelukoodi ei voi olla tyhjä' },
  serviceId_accepted: { defaultMessage: 'Palvelukoodi hyväksytty' },
  password_accepted: { defaultMessage: 'Salasana hyväksytty' },
  serviceId_not_found: { defaultMessage: 'Virheellinen palvelukoodi' },
  password_not_found: { defaultMessage: 'Virheellinen salasana' },
  form_company_name: { defaultMessage: 'Organisaatio' },
  form_all: { defaultMessage: 'Kaikki vastaukset' },
  form_locked_by_serviceCode: {
    defaultMessage:
      'Lomake on toistaiseksi lukittu. Avaa lomake syöttämällä palvelukoodi.',
  },
  form_locked_by_password: {
    defaultMessage:
      'Lomake on toistaiseksi lukittu. Avaa lomake syöttämällä salasana.',
  },
  form_inquiries: { defaultMessage: 'Lomakkeet' },
  form_accepted: { defaultMessage: 'Hyväksytyt' },
  form_rejected: { defaultMessage: 'Hylätyt' },
  form_pending: { defaultMessage: 'Uudet' },
  form_status_change: { defaultMessage: 'Vastauksen status muokattu' },
  form_status_change_error: {
    defaultMessage: 'Vastausten tilan muokkaus epäonnistui',
  },
  form_fetch_error: { defaultMessage: 'Lomakevastausten haku epäonnistui' },
  form_submitted: { defaultMessage: 'Lomakevastaus lähetetty' },
  form_account_submitted: { defaultMessage: 'Hakemus lähetetty' },
  form_submit_failed: { defaultMessage: 'Lomakkeen lähetys epäonnistui' },
  form_account_submit_failed: {
    defaultMessage: 'Hakemuksen lähetys epäonnistui',
  },
  form_export_failed: { defaultMessage: 'Lomakkeen vienti epäonnistui' },
  form_export_empty_list: { defaultMessage: 'Valitse ainakin yksi lomake' },
  form_account_renewal: { defaultMessage: 'Tunnusten uusiminen' },
  form_account_renewal_desc: {
    defaultMessage:
      'Aseta tunnuksillesi uusi voimassaolon päättymispäivä. ' +
      'Voit asettaa uuden päivämäärän korkeintaan 13 kuukauden päähän',
  },
  form_account_renewal_success_desc: {
    defaultMessage:
      'Olet uusinut Pirkanmaan hyvinvointialueen tunnuksesi ja saat vahvistuksen työsähköpostiisi. ' +
      'Voit kirjautua ulos palvelusta.',
  },
  token_expired: { defaultMessage: 'Linkki on vanhentunut.' },
  // Profile
  profile: { defaultMessage: 'Käyttäjän tiedot' },
  profile_title: { defaultMessage: 'Omat tiedot' },
  profile_e_concent: { defaultMessage: 'Sähköisen asioinnin suostumus' },
  profile_edit_assent: { defaultMessage: 'Muokkaa suostumusta' },
  profile_revoke_assent: { defaultMessage: 'Poista suostumus' },
  profile_modify_revoke: {
    defaultMessage:
      'Kyseistä suostumusta ei voi muokata. Jos poistat suostumuksen sinut kirjataan ulos.',
  },
  profile_revoke_concent: { defaultMessage: 'Poista suostumus' },
  profile_revoke_concent_error: {
    defaultMessage:
      'Suostumuksen poistaminen epäonnistui, kokeile hetken kuluttua uudelleen.',
  },
  // Footer
  footer_terms: { defaultMessage: 'Tietosuojaseloste' },
  footer_accessability: { defaultMessage: 'Saavutettavuusseloste' },
  footer_contact: {
    defaultMessage:
      'Jos sinulla on kysyttävää lomakkeista, ota yhteyttä omaan hoitoyksikköösi.',
  },
  footer_contact_dev: {
    defaultMessage:
      'Jos huomaat palvelussa teknisen virheen, täytä ystävällisesti vikailmoituslomake. Palvelua koskevat kehitysideat ja palautteet voit ilmoittaa palautelomakkeella.',
  },
  // External links
  link_psychotherapy: {
    defaultMessage: 'Rekisterin etäkäyttö',
  },
  //Organization listing
  organization_title: { defaultMessage: 'Organisaatioiden listaus' },
  organizations_title: { defaultMessage: 'Organisaatiot' },
  back_to_organizations: { defaultMessage: 'Takaisin organisaatioihin' },
  back_to_sections: { defaultMessage: 'Takaisin osa-alueisiin' },
  confirm_dialog: { defaultMessage: 'Vahvista toiminto' },
  confirm_delete: { defaultMessage: 'Haluatko varmasti poistaa tämän' },
  //Section listing
  section_title: { defaultMessage: 'Osa-alueet' },
  section_description: {
    defaultMessage: 'Osa-alueen kuvaus',
  },
  description_update_success: {
    defaultMessage: 'Osa-alueen kuvaus päivitetty',
  },
  description_update_failed: {
    defaultMessage: 'Osa-alueet kuvauksen päivitys epäonnistui',
  },
  //User listing
  manager_title: { defaultMessage: 'Managerit' },
  applicant_title: { defaultMessage: 'Hakijat' },
  //Ext-management dialog
  organization: { defaultMessage: 'Organisaatio' },
  organization_create: { defaultMessage: 'Täytä organisaation tiedot' },
  organization_edit: { defaultMessage: 'Muokkaa organisaation tietoja' },
  organization_exists: {
    defaultMessage: 'Samanniminen organisaatio on jo olemassa',
  },
  section: { defaultMessage: 'Osa-alue' },
  section_create: { defaultMessage: 'Täytä osa-alueen tiedot' },
  section_edit: { defaultMessage: 'Muokkaa osa-alueen tietoja' },
  section_exists: {
    defaultMessage: 'Samanniminen osa-alue on jo olemassa',
  },
  manager: { defaultMessage: 'Manageri' },
  manager_create: { defaultMessage: 'Täytä managerin tiedot' },
  manager_edit: { defaultMessage: 'Muokkaa managerin tietoja' },
  manager_duplicate: { defaultMessage: 'Manageri on jo lisätty osa-alueelle' },
  applicant: { defaultMessage: 'Hakija' },
  applicant_create: { defaultMessage: 'Täytä hakijan tiedot' },
  applicant_edit: { defaultMessage: 'Muokkaa hakijan tietoja' },
  applicant_duplicate: { defaultMessage: 'Hakija on jo lisätty osa-alueelle' },
  //Inbox
  inbox: { defaultMessage: 'Viestit' },
  subject: { defaultMessage: 'Aihe' },
  date: { defaultMessage: 'Päiväys' },
  body: { defaultMessage: 'Viesti' },
  message: { defaultMessage: 'Viesti' },
  // Manager login
  login_method: {
    defaultMessage: 'Valitse miten haluat kirjautua sisään',
  },
  manager_concent: {
    defaultMessage:
      'Vastaan organisaatiossani tunnuksista, ja olen vastuussa myöntämistäni tunnuksista ja oikeuksista',
  },
  manager_accept: {
    defaultMessage: 'Vahvistan ja jatkan',
  },
  manager_decline: {
    defaultMessage: 'Keskeytän',
  },
});
