import React from 'react';
import { useIntl } from 'react-intl';
import PageWithDrawer from 'components/layouts/PageWithDrawer';
import messages from 'messages';
import { FormListView } from 'components/FormList';
import { RuntimeConfigProvider } from '@super-template/core/src/runtimeConfig/index.js';
import runtimeConfigDefaults from '../../../runtimeConfigDefaults.json';

export const path = '/forms';

export const component = function Forms() {
  const intl = useIntl();

  return (
    <PageWithDrawer title={intl.formatMessage(messages.forms)}>
      <RuntimeConfigProvider defaults={runtimeConfigDefaults}>
        <FormListView />
      </RuntimeConfigProvider>
    </PageWithDrawer>
  );
};
