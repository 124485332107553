import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Form } from '@visma/formula';
import { useKeycloak } from '@react-keycloak/web';

import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import { ServiceContext } from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';

import { submitFormResponse, sendCredentialApplicationToEnsemble } from 'api';
import messages from 'messages';
import { SnackbarVariant } from '../../constants';
import { getUserStatus } from 'userUtils';
import runtimeConfig from '@super-template/core/src/runtimeConfig';

import { KEIKKALAISTUNNUSTILAUS, TUNNUSTILAUS } from './FormTypes.js';

import {
  getApplicant,
  getApplicantDates,
  getOrganizationsForApplicant,
  getSectionsForApplicant,
} from 'api';

export const FormContent = ({ form, id }) => {
  const serviceContext = useContext(ServiceContext);
  const { OrganizationData } = serviceContext;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const { idTokenParsed } = keycloak;
  const formRef = useRef();
  const userStatus = getUserStatus();

  const isCredentialApplicationForm = (userStatus) => {
    return (
      userStatus === 'applicant' &&
      form._id.toString() === runtimeConfig.applicationForm.id.toString()
    );
  };

  const [formData, setFormData] = useState({});
  const [sections, setSections] = useState([]);
  const [applicantDates, setApplicantDates] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (isCredentialApplicationForm(userStatus)) {
      async function fetchData() {
        const applicant = await getApplicant();
        const appDates = await getApplicantDates();
        const sections = await getSectionsForApplicant();
        const organization = await getOrganizationsForApplicant();

        initializeCredentialApplicationForm(
          applicant.data,
          appDates.data,
          sections.data,
          organization.data
        );
        setSections(sections.data);
        setApplicantDates(appDates.data);
        setOrganizations(organization.data);
      }
      fetchData();
    } else if (
      form._id.toString() === runtimeConfig.oldApplicationForm.id.toString()
    ) {
      initializeOldApplicationFormData();
    } else if (
      form._id.toString() === runtimeConfig.sidonnaisuusForm.id.toString()
    ) {
      initializeSidonnaisuusFormData();
    } else if (
      form._id.toString() === runtimeConfig.henkilotietoForm.id.toString()
    ) {
      initializeHenkilotietoFormData();
    } else if (
      form._id.toString() === runtimeConfig.keikkalaistunnusForm.id.toString()
    ) {
      initializeKeikkalaistunnusFormData();
    }
    // eslint-disable-next-line
  }, [form._id, userStatus]);

  const initializeCredentialApplicationForm = (
    appData,
    appDatesData,
    secData,
    orgData
  ) => {
    const organizationAndSection = [];

    for (let i = 0; i < secData.length; i++) {
      const orgIdMatch = Array.isArray(orgData)
        ? orgData.find((o) => o.id === secData[i].organizationId)
        : orgData;
      if (orgIdMatch) {
        const secIdMatch = Array.isArray(appDatesData)
          ? appDatesData.find((o) => o.sectionId === secData[i].id)
          : appDatesData;
        if (secIdMatch) {
          organizationAndSection.push({
            [runtimeConfig.applicationForm.fieldKey.sectionName]:
              secData[i].sectionName,
            [runtimeConfig.applicationForm.fieldKey
              .validityPeriod]: `${secIdMatch.validityPeriodStartDate} - ${secIdMatch.validityPeriodExpiryDate}`,
            [runtimeConfig.applicationForm.fieldKey.organizationName]:
              orgIdMatch.organizationName,
          });
        }
      }
    }

    setFormData({
      [runtimeConfig.applicationForm.fieldKey.ssn]:
        appData.socialSecurityNumber,
      [runtimeConfig.applicationForm.fieldKey.givenName]:
        idTokenParsed.given_name,
      [runtimeConfig.applicationForm.fieldKey.familyName]:
        idTokenParsed.family_name,
      [runtimeConfig.applicationForm.fieldKey.givenNames]:
        idTokenParsed.given_names,
      [runtimeConfig.applicationForm.fieldKey.organizationAndSection]:
        organizationAndSection,
      [runtimeConfig.applicationForm.fieldKey.email]: appData.email,
    });
  };

  const initializeOldApplicationFormData = () => {
    setFormData({
      [runtimeConfig.oldApplicationForm.fieldKey.givenName]:
        idTokenParsed.given_name,
      [runtimeConfig.oldApplicationForm.fieldKey.givenNames]:
        idTokenParsed.given_names,
      [runtimeConfig.oldApplicationForm.fieldKey.familyName]:
        idTokenParsed.family_name,
      [runtimeConfig.oldApplicationForm.fieldKey.ssn]: idTokenParsed.ssn,
      [runtimeConfig.oldApplicationForm.fieldKey.organizationName]:
        OrganizationData.companyName,
      [runtimeConfig.oldApplicationForm.fieldKey.sectionName]:
        OrganizationData.systemInfo,
      [runtimeConfig.oldApplicationForm.fieldKey.serviceCode]:
        OrganizationData.pin,
    });
  };

  const initializeSidonnaisuusFormData = () => {
    setFormData({
      [runtimeConfig.sidonnaisuusForm.fieldKey.givenNames]:
        idTokenParsed.given_names,
      [runtimeConfig.sidonnaisuusForm.fieldKey.familyName]:
        idTokenParsed.family_name,
      [runtimeConfig.sidonnaisuusForm.fieldKey.ssn]: idTokenParsed.ssn,
    });
  };

  const initializeHenkilotietoFormData = () => {
    setFormData({
      [runtimeConfig.henkilotietoForm.fieldKey.givenName]:
        idTokenParsed.given_name,
      [runtimeConfig.henkilotietoForm.fieldKey.givenNames]:
        idTokenParsed.given_names,
      [runtimeConfig.henkilotietoForm.fieldKey.familyName]:
        idTokenParsed.family_name,
      [runtimeConfig.henkilotietoForm.fieldKey.ssn]: idTokenParsed.ssn,
    });
  };

  const initializeKeikkalaistunnusFormData = () => {
    setFormData({
      [runtimeConfig.keikkalaistunnusForm.fieldKey.givenNames]:
        idTokenParsed.given_names,
      [runtimeConfig.keikkalaistunnusForm.fieldKey.givenName]:
        idTokenParsed.given_name,
      [runtimeConfig.keikkalaistunnusForm.fieldKey.familyName]:
        idTokenParsed.family_name,
      [runtimeConfig.keikkalaistunnusForm.fieldKey.ssn]: idTokenParsed.ssn,
    });
  };

  const handlePostSubmit = (submitResponse) => {
    if (!submitResponse || !submitResponse.attributes.id) {
      return null;
    }

    let credentialsStartDateField;
    if (
      form._id.toString() === runtimeConfig.oldApplicationForm.id.toString()
    ) {
      credentialsStartDateField =
        formRef.current.formContext.formData[
          runtimeConfig.oldApplicationForm.fieldKey.credentialsStartDate
        ];
    } else {
      credentialsStartDateField = null;
    }

    const formResponse = {
      formulaResponseId: submitResponse.attributes.id,
      formId: form._id,
      title: `${idTokenParsed.given_name} ${idTokenParsed.family_name} - ${form.title}`,
      credentialsStartDate: credentialsStartDateField
        ? credentialsStartDateField
        : null,
    };

    submitFormResponse(formResponse)
      .then(() => {
        if (isCredentialApplicationForm(userStatus)) {
          enqueueSnackbar(intl.formatMessage(messages.form_account_submitted), {
            variant: SnackbarVariant.SUCCESS,
            autoHideDuration: null,
            onClick: () => {
              closeSnackbar();
            },
          });
        } else {
          enqueueSnackbar(intl.formatMessage(messages.form_submitted), {
            variant: SnackbarVariant.SUCCESS,
          });
        }
      })
      .catch(() => {
        if (isCredentialApplicationForm(userStatus)) {
          enqueueSnackbar(
            intl.formatMessage(messages.form_account_submit_failed),
            {
              variant: SnackbarVariant.ERROR,
              autoHideDuration: null,
              onClick: () => {
                closeSnackbar();
              },
            }
          );
        } else {
          enqueueSnackbar(intl.formatMessage(messages.form_submit_failed), {
            variant: SnackbarVariant.ERROR,
          });
        }
      });
  };

  const handleOnSubmit = () => {
    const formResponseData = formRef.current.formContext.formData;
    let data;
    let formType;

    switch (form._id.toString()) {
      case runtimeConfig.keikkalaistunnusForm.id.toString():
        formType = KEIKKALAISTUNNUSTILAUS;
        data = {
          SSN: formResponseData[
            runtimeConfig.keikkalaistunnusForm.fieldKey.ssn
          ],
          Etunimi: idTokenParsed.given_names,
          Sukunimi: idTokenParsed.family_name,
          Kutsumanimi: idTokenParsed.given_name,
          matkapuhelin:
            formResponseData[
              runtimeConfig.keikkalaistunnusForm.fieldKey.phoneNumber
            ],
          svnumber:
            formResponseData[
              runtimeConfig.keikkalaistunnusForm.fieldKey.svNumber
            ],
          soteammattikortti:
            formResponseData[
              runtimeConfig.keikkalaistunnusForm.fieldKey.soteCardNumber
            ],
          usertype: 3,
        };
        break;

      case runtimeConfig.applicationForm.id:
      default:
        formType = TUNNUSTILAUS;
        const sectionsArr = processSectionsData(sections, applicantDates);
        data = {
          SSN: formResponseData[runtimeConfig.applicationForm.fieldKey.ssn],
          Etunimi: idTokenParsed.given_names,
          Sukunimi: idTokenParsed.family_name,
          Kutsumanimi: idTokenParsed.given_name,
          email: formResponseData[runtimeConfig.applicationForm.fieldKey.email],
          sections: sectionsArr,
        };
    }

    sendCredentialApplicationToEnsemble(data, formType)
      .then(() => {
        enqueueSnackbar(intl.formatMessage(messages.form_account_submitted), {
          variant: SnackbarVariant.SUCCESS,
          autoHideDuration: null,
          onClick: () => {
            closeSnackbar();
          },
        });
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage(messages.form_account_submit_failed),
          {
            variant: SnackbarVariant.ERROR,
            autoHideDuration: null,
            onClick: () => {
              closeSnackbar();
            },
          }
        );
      });
    return false;
  };

  let credAppFormAndSingleOrg = false;
  const isKeikkalaisForm =
    form._id.toString() === runtimeConfig.keikkalaistunnusForm.id.toString();

  if (form._id.toString() === runtimeConfig.applicationForm.id.toString()) {
    if (organizations.length === 1) {
      credAppFormAndSingleOrg = true;
    }
  }

  const processSectionsData = (sections, appDates) => {
    const sectionsData = [];

    const sectionArray = Array.isArray(sections) ? sections : [sections];
    const datesArray = Array.isArray(appDates) ? appDates : [appDates];

    for (const section of sectionArray) {
      for (const date of datesArray) {
        if (section.id === date.sectionId) {
          sectionsData.push({
            id: section.id,
            Startdate: date.validityPeriodStartDate,
            EndDate: date.validityPeriodExpiryDate,
            costCenterID: section.costCentre.match(/\d+/)[0],
            titleCode: section.title.match(/\d+/)[0],
            palveluKoodi: section.serviceCode,
            company: section.organizationName,
            userType: section.externalType.split(' ')[0],
          });
        }
      }
    }

    return sectionsData;
  };

  return (
    <>
      {OrganizationData && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {!credAppFormAndSingleOrg && !isKeikkalaisForm && (
            <Form
              ref={formRef}
              id={id}
              onPostSubmit={handlePostSubmit}
              formData={formData}
            />
          )}

          {(credAppFormAndSingleOrg || isKeikkalaisForm) && (
            <Form
              ref={formRef}
              id={id}
              onSubmit={handleOnSubmit}
              formData={formData}
            />
          )}
        </ErrorBoundary>
      )}
    </>
  );
};
