import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

import messages from 'messages.js';
import { ManagerList } from './ManagerList';
import { ApplicantList } from './ApplicantList';
import { SmallSectionList } from './SmallSectionList';
import { useKeycloak } from '@react-keycloak/web';
import Search from '../Search.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const UserList = () => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { orgId } = useParams();
  const { keycloak } = useKeycloak();

  const hasOrganizationAdminRole =
    keycloak.hasResourceRole('organizationAdmin');

  const handleBackButtonClick = () => {
    history.push(`/listview/${orgId}`);
  };

  return (
    <>
      <Search />
      <SmallSectionList />
      <div className={classes.backButton}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleBackButtonClick()}
        >
          {intl.formatMessage(messages.back)}
        </Button>
      </div>
      {hasOrganizationAdminRole && (
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
          <ManagerList />
        </div>
      )}
      <ApplicantList />
    </>
  );
};
