import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Suspense } from 'react';
import NavBar from './NavBar';

import Drawer from './Drawer';
import Footer from './Footer';
import useSetDocumentTitle from './useSetDocumentTitle';

const useStyles = makeStyles((theme) => ({
  pageWithDrawerRoot: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '75vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '82vh',
    },
    '& > *': {
      margin: '1rem',
    },
    background: theme.palette.common.greyLight,
  },
  content: {
    background: theme.palette.common.white,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3, 2, 3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 1, 2),
      margin: 0,
    },
  },
}));

export default function PageWithDrawer({ title, children }) {
  const classes = useStyles();
  useSetDocumentTitle(title);

  return (
    <>
      <NavBar title={title} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.pageWithDrawerRoot}
      >
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <Drawer title={title} />
        </Grid>
        <Grid
          item
          xs={10}
          sm={8}
          md={7}
          lg={6}
          className={classes.content}
          role="main"
          data-type={'content'}
        >
          <Suspense fallback={<CircularProgress />}>{children}</Suspense>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
