import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import PageWithDrawer from 'components/layouts/PageWithDrawer';
import messages from 'messages';
import Private from './Private';

export const path = '/';
export const exact = true;

export const component = function Home() {
  const intl = useIntl();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.hasResourceRole('organizationAdmin')) {
      history.push('/listview');
      return <></>;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageWithDrawer title={intl.formatMessage(messages.home)}>
        <Private />
      </PageWithDrawer>
    </>
  );
};
