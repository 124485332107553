import { useIntl } from 'react-intl';
import { Typography, Grid, Link } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

import { useUserClaim } from '@super-template/axios-keycloak-react-auth-integration/src/useUserClaim';

import RegistrationNav from './WizardControls';
import messages from 'messages';

const Identify = ({ classes, handleNextStep, handlePreviousStep }) => {
  const ssn = useUserClaim('ssn');
  const city = useUserClaim('city');
  const postNumber = useUserClaim('postNumber');
  const streetAddress = useUserClaim('address');
  const name = useUserClaim('name');
  const intl = useIntl();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
    >
      <Grid item md={5} sm={11} className={classes.section}>
        <Typography variant="subtitle2" className={classes.paragraph}>
          {intl.formatMessage(messages.registration_concent)}
        </Typography>
        <Typography variant="body2" className={classes.paragraph}>
          {intl.formatMessage(messages.registration_person_information)}
        </Typography>
        <Typography>
          <Link
            href="https://www.pirha.fi/tietosuoja/sahkoinen-asiointi"
            target="_blank"
          >
            <LaunchIcon />
            {intl.formatMessage(messages.registration_read_terms)}
          </Link>
        </Typography>
      </Grid>
      <Grid item md={5} sm={11} className={classes.section}>
        <Typography variant="overline">
          {intl.formatMessage(messages.sso)}
        </Typography>
        <Typography>{ssn ? ssn : '000000-123A'}</Typography>
        <Typography variant="overline" className={classes.overline}>
          {intl.formatMessage(messages.name)}
        </Typography>
        <Typography>{name}</Typography>
        <Typography variant="overline" className={classes.overline}>
          {intl.formatMessage(messages.address)}
        </Typography>
        <Typography>
          {streetAddress}
          <br />
          {city} {postNumber}
        </Typography>
      </Grid>
      <RegistrationNav
        handleNext={handleNextStep}
        handlePrevious={handlePreviousStep}
        previousText={intl.formatMessage(messages.interrupt)}
        nextText={intl.formatMessage(messages.next)}
      />
    </Grid>
  );
};

export default Identify;
