import { useContext } from 'react';
import { FormList } from './FormList';
import { FormResponse } from './FormResponse';

import { ServiceContext } from '@super-template/axios-keycloak-react-auth-integration/src/ServiceProvider';

export const FormListView = () => {
  const serviceContext = useContext(ServiceContext);
  const { isAdmin } = serviceContext;

  return (
    <>
      {isAdmin ? (
        <>
          <FormResponse />
          <div style={{ marginBottom: '1rem' }} />
        </>
      ) : (
        <>
          <FormList />
        </>
      )}
    </>
  );
};
