import React, { useState, useEffect } from 'react';
import cuid from 'cuid';
import { useIntl } from 'react-intl';
import { Paper, Tabs, Tab, Button, List, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import runtimeConfig from '@super-template/core/src/runtimeConfig';

import messages from 'messages';
import { updateFormResponseList, fetchCSVByIds } from 'api';
import { FormRow } from './FormResponseRow';
import { TabPanel } from './FormResponseTabPanel';
import { ResponseStatus, SnackbarVariant } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    background: theme.palette.primary.hallintoBlue,
    color: theme.palette.white,
  },
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem 0',
    },
  },
  actionRow: {
    display: 'flex',
    marginTop: '.8rem',
    '& > button': {
      fontSize: '0.8em',
      padding: '8px 30px',
      marginRight: '.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem',
    },
  },
  textField: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  floatRight: {
    marginLeft: 'auto',
  },
  noFormsFound: {
    textAlign: 'center',
  },
}));

const FormResponseList = ({ forms, fetchResponses }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [formResponses, setFormResponses] = useState(
    [...forms].sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    )
  );
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    let filtered = [...forms];

    if (query) {
      filtered = filtered.filter((form) =>
        form?.title?.toLowerCase().includes(query.toLowerCase())
      );
      setCurrentPage(1);
    }

    switch (tabValue) {
      case 1:
        filtered = filtered
          .filter((form) => form.responseStatus === ResponseStatus.PENDING)
          .sort(
            (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
          );
        break;
      case 2:
        filtered = filtered
          .filter((form) => form.responseStatus === ResponseStatus.ACCEPTED)
          .sort(
            (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
          );
        break;
      case 3:
        filtered = filtered
          .filter((form) => form.responseStatus === ResponseStatus.REJECTED)
          .sort(
            (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
          );
        break;
      default:
        filtered = filtered.sort(
          (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
        );
        break;
    }

    setFormResponses(filtered);
  }, [forms, query, tabValue]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setQuery(search);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setCurrentPage(1);
  };

  const onCheckChange = (event) => {
    const copy = formResponses.map((form) => {
      if (form.id === Number(event.target.value)) {
        form.checked = !form.checked;
      }
      return form;
    });
    setFormResponses(copy);
    setAllSelected(copy.every((form) => form.checked));
  };

  const pendingFormResponses = async () => {
    await updateFormsState(ResponseStatus.PENDING);
  };

  const acceptFormResponses = async () => {
    await updateFormsState(ResponseStatus.ACCEPTED);
  };
  const rejectFormResponses = async () => {
    await updateFormsState(ResponseStatus.REJECTED);
  };

  const updateFormsState = async (status) => {
    const responsesToUpdate = formResponses
      .filter((form) => form.checked)
      .map((response) => {
        response.responseStatus = status;
        return response;
      });
    try {
      await updateFormResponseList(responsesToUpdate);
      enqueueSnackbar(intl.formatMessage(messages.form_status_change), {
        variant: SnackbarVariant.SUCCESS,
      });
      await fetchResponses();
    } catch (err) {
      enqueueSnackbar(intl.formatMessage(messages.form_status_change_error), {
        variant: SnackbarVariant.ERROR,
      });
    }
  };

  const exportForms = async () => {
    const exportList = formResponses
      .filter((form) => form.checked)
      .map((form) => {
        return form.formulaResponseId;
      });
    if (exportList.length === 0) {
      enqueueSnackbar(intl.formatMessage(messages.form_export_empty_list), {
        variant: SnackbarVariant.WARNING,
      });
      return;
    }
    fetchCSVByIds(exportList)
      .then((response) => {
        const file = new Blob([response.data], {
          type: 'text/csv;charset=utf-8;',
        });
        const a = document.createElement('a');
        a.style = 'display: none';
        const now = new Date();

        document.body.appendChild(a);
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = `Hallinto-export_${now.toISOString().split('T')[0]}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.form_export_failed), {
          variant: SnackbarVariant.ERROR,
        });
      });
  };

  const handleSelectAll = () => {
    const copy = formResponses.map((form) => {
      form.checked = !allSelected;
      return form;
    });
    setFormResponses(copy);
    setAllSelected(!allSelected);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        variant="fullWidth"
        value={tabValue}
        onChange={handleTabChange}
        textColor="white"
        indicatorColor="secondary"
        centered
        className={classes.header}
      >
        <Tab label={intl.formatMessage(messages.form_all)} />
        <Tab label={intl.formatMessage(messages.form_pending)} />
        <Tab label={intl.formatMessage(messages.form_accepted)} />
        <Tab label={intl.formatMessage(messages.form_rejected)} />
      </Tabs>
      <div className={classes.actionRow}>
        <form className={classes.form} onSubmit={handleSearchSubmit}>
          <TextField
            className={classes.textField}
            value={search}
            onChange={handleSearchChange}
          />
          <Button className={classes.button} type="submit" variant="outlined">
            Hae
          </Button>
        </form>
        {formResponses && formResponses.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectAll}
            className={classes.floatRight}
          >
            {allSelected
              ? intl.formatMessage(messages.select_none)
              : intl.formatMessage(messages.select_all)}
          </Button>
        )}
      </div>
      <TabPanel value={tabValue} index={0}>
        <Forms
          forms={formResponses}
          onCheckChange={onCheckChange}
          checkable={true}
          currentPage={currentPage}
          setCurrentPage={(v) => setCurrentPage(v)}
        />
        {formResponses &&
          (formResponses.length > 0 ? (
            <div className={classes.actionRow}>
              <Button
                variant="contained"
                color="primary"
                onClick={pendingFormResponses}
                className={classes.floatRight}
              >
                {intl.formatMessage(messages.new)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={acceptFormResponses}
              >
                {intl.formatMessage(messages.accept)}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={rejectFormResponses}
              >
                {intl.formatMessage(messages.reject)}
              </Button>
              <Button variant="contained" color="primary" onClick={exportForms}>
                {intl.formatMessage(messages.export_csv)}
              </Button>
            </div>
          ) : (
            <p className={classes.noFormsFound}>
              {intl.formatMessage(messages.no_forms_found)}
            </p>
          ))}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Forms
          forms={formResponses}
          onCheckChange={onCheckChange}
          checkable={true}
          currentPage={currentPage}
          setCurrentPage={(v) => setCurrentPage(v)}
        />
        {formResponses &&
          (formResponses.length > 0 ? (
            <div className={classes.actionRow}>
              <Button
                variant="contained"
                color="primary"
                onClick={pendingFormResponses}
                className={classes.floatRight}
              >
                {intl.formatMessage(messages.new)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={acceptFormResponses}
              >
                {intl.formatMessage(messages.accept)}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={rejectFormResponses}
              >
                {intl.formatMessage(messages.reject)}
              </Button>
            </div>
          ) : (
            <p className={classes.noFormsFound}>
              {intl.formatMessage(messages.no_forms_found)}
            </p>
          ))}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Forms
          forms={formResponses}
          checkable={false}
          currentPage={currentPage}
          setCurrentPage={(v) => setCurrentPage(v)}
        />
        {formResponses && formResponses.length > 0 ? null : (
          <p className={classes.noFormsFound}>
            {intl.formatMessage(messages.no_forms_found)}
          </p>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Forms
          forms={formResponses}
          checkable={false}
          currentPage={currentPage}
          setCurrentPage={(v) => setCurrentPage(v)}
        />
        {formResponses && formResponses.length > 0 ? null : (
          <p className={classes.noFormsFound}>
            {intl.formatMessage(messages.no_forms_found)}
          </p>
        )}
      </TabPanel>
    </Paper>
  );
};

const Forms = ({
  forms,
  onCheckChange,
  checkable,
  currentPage,
  setCurrentPage,
}) => {
  const classes = useStyles();
  const itemPerPage = runtimeConfig.itemsPerPage;

  const paginatedFormList = () => {
    return forms.slice(
      (currentPage - 1) * itemPerPage,
      (currentPage - 1) * itemPerPage + itemPerPage
    );
  };

  return (
    <>
      <List className={classes.listRoot}>
        {paginatedFormList().map((form) => (
          <FormRow
            form={form}
            checkable={checkable}
            onCheckChange={onCheckChange}
            key={cuid()}
          />
        ))}
      </List>
      {Math.ceil(forms.length / itemPerPage) > 1 && (
        <Pagination
          count={Math.ceil(forms.length / itemPerPage)}
          variant="outlined"
          color="primary"
          onChange={(event, value) => setCurrentPage(value)}
          page={currentPage}
          className={classes.pagination}
        />
      )}
    </>
  );
};

export default FormResponseList;
