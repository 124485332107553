import { Suspense } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';

import NavBar from './NavBar';
import useSetDocumentTitle from './useSetDocumentTitle';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    padding: '2rem 0',
    [theme.breakpoints.up('sm')]: {
      minHeight: '75vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '82vh',
    },
  },
}));

export default function Page({ title, children }) {
  const classes = useStyles();
  useSetDocumentTitle(title);
  const { initialized } = useKeycloak();

  return (
    <>
      <NavBar title={title} />
      <Grid
        container
        justifyContent="center"
        className={classes.pageRoot}
        role="main"
      >
        <Grid item md={7} xs={10}>
          {initialized ? (
            <Suspense fallback={<CircularProgress />}>{children}</Suspense>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
