import { TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';

export default function ErrorFallback({ error }) {
  const handleFocus = (event) => event.target.select();

  return (
    <Alert severity="warning">
      <AlertTitle>
        <FormattedMessage defaultMessage="Jotain meni pieleen" />
      </AlertTitle>
      {process.env.NODE_ENV === 'development' && (
        <TextField
          multiline
          maxRows={4}
          value={error.stack ?? error}
          variant="outlined"
          fullWidth
          readOnly
          onFocus={handleFocus}
        />
      )}
    </Alert>
  );
}
