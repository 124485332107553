import React from 'react';
import { useIntl } from 'react-intl';
import Page from 'components/layouts/Page';
import messages from 'messages';

import { RegistrationWizard } from 'components/RegistrationWizard/';

export const path = '/registration';

export const component = function Registration() {
  const intl = useIntl();
  return (
    <Page title={intl.formatMessage(messages.forms)}>
      <RegistrationWizard />
    </Page>
  );
};
