import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1, 0),
  },
}));

export const TermCheckbox = ({ label, name, value }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          icon={<RadioButtonUncheckedIcon color="primary" />}
          checkedIcon={<RadioButtonCheckedIcon color="primary" />}
          name={name}
        />
      }
      label={label}
      className={classes.padding}
    />
  );
};
