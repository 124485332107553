import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import messages from 'messages';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    margin: '1rem 0',
    '& > *': {
      marginBottom: '1rem',
      width: '25ch',
    },
  },
  paragraph: {
    margin: '0.5rem 0',
  },
}));

export const RenewalSuccessView = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Typography color="initial" variant="h1">
        {intl.formatMessage(messages.form_account_renewal)}
      </Typography>

      <Typography color="initial" variant="body1" className={classes.paragraph}>
        {intl.formatMessage(messages.form_account_renewal_success_desc)}
      </Typography>
    </>
  );
};
